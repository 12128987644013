define("ember-svg-jar/inlined/arrow-left-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#arrow-left-pagination_svg__clip0_173_12420)\"><path d=\"M12.576 1.455a1.501 1.501 0 01-.03 2.122l-8.15 7.928 8.15 7.929a1.5 1.5 0 01-2.091 2.152l-8.82-8.58a2.101 2.101 0 01-.47-2.316c.109-.258.268-.49.47-.685l8.82-8.58a1.5 1.5 0 012.12.03z\"/></g><defs><clipPath id=\"arrow-left-pagination_svg__clip0_173_12420\"><path fill=\"#fff\" d=\"M0 0h14v23H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 14 23"
    }
  };
});
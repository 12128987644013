define("@tryghost/ember-promise-modals/templates/components/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    {{!-- template-lint-disable no-action --}}
  <div id={{this.modalElementId}} class="epm-modal {{this.optionsClassName}} {{this.animatingOutClass}}">
    {{#let (component (ensure-safe-component @modal._name) data=@modal._data close=(action "close")) as |ModalComponent|}}
      <ModalComponent />
    {{/let}}
  </div>
  */
  {
    "id": "t/mmySqw",
    "block": "{\"symbols\":[\"ModalComponent\",\"@modal\"],\"statements\":[[10,\"div\"],[15,1,[32,0,[\"modalElementId\"]]],[15,0,[31,[\"epm-modal \",[32,0,[\"optionsClassName\"]],\" \",[32,0,[\"animatingOutClass\"]]]]],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,1],[[32,2,[\"_name\"]]],null]],[[\"data\",\"close\"],[[32,2,[\"_data\"]],[30,[36,0],[[32,0],\"close\"],null]]]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"ensure-safe-component\",\"component\",\"let\"]}",
    "moduleName": "@tryghost/ember-promise-modals/templates/components/modal.hbs"
  });
});
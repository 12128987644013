define("ember-svg-jar/inlined/thumbs-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M22 14.429h-3.445a1.905 1.905 0 00-1.543.794l-4.617 7.095a1.622 1.622 0 01-2.783-.233 1.597 1.597 0 01-.103-1.1l.833-3.142a1.867 1.867 0 00-.993-2.164 1.911 1.911 0 00-.833-.193h-4.63A1.881 1.881 0 012.08 13.06v-.011l1.8-6.008v-.016c.733-2.36 1.992-3.97 4.47-3.97 5.933 0 5.594-.684 12.523 2.818.377.188.752.379 1.126.572V16.5\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
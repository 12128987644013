define("ember-svg-jar/inlined/stats-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.836 3.027c-.209.03-.452.108-.659.213-.55.277-.926.774-1.113 1.473l-.052.195v14.256l.054.204c.212.797.778 1.336 1.647 1.568l.195.052h20.256l.204-.054c.112-.029.314-.108.45-.174.2-.099.285-.16.46-.326.118-.113.253-.262.3-.332.19-.287.356-.708.39-.986.008-.072.024-.125.035-.118.012.007.021-3.057.021-6.995 0-4.646-.008-7.013-.024-7.023-.013-.008-.024-.05-.024-.094 0-.134-.092-.449-.194-.662-.273-.572-.76-.954-1.482-1.16l-.184-.052-10.068-.003c-5.596-.002-10.132.006-10.212.018m.101 1.524c-.15.046-.284.181-.356.36l-.057.141V19.02l.057.116c.07.144.2.241.405.304.154.048.24.048 10.048.048 8.88 0 9.906-.004 10.019-.037a.586.586 0 00.366-.362l.057-.141V4.98l-.057-.116c-.07-.144-.2-.241-.405-.304-.154-.048-.238-.048-10.059-.046-8.65.001-9.918.006-10.018.037M.012 12.024c0 3.861.003 5.44.006 3.51.003-1.93.003-5.09 0-7.02-.003-1.93-.006-.351-.006 3.51m20.07-4.511a.746.746 0 00-.37.219c-.054.057-.987 1.438-2.072 3.068a649.896 649.896 0 01-1.995 2.987c-.014.015-.489-.442-1.251-1.202-1.4-1.396-1.334-1.343-1.685-1.324a.72.72 0 00-.301.066c-.074.037-.48.425-1.294 1.237l-1.187 1.183-1.193-2.2c-.656-1.21-1.23-2.242-1.277-2.295a.749.749 0 00-1.065-.05c-.123.118-3.298 5.398-3.36 5.586a.809.809 0 00.039.533.83.83 0 00.377.368c.113.052.157.059.327.051.236-.01.386-.076.52-.228.048-.055.637-1.016 1.307-2.136.671-1.12 1.232-2.049 1.247-2.065.02-.02.303.482 1.131 2.01.607 1.121 1.142 2.09 1.189 2.152.122.165.311.256.551.267.342.016.274.07 1.734-1.385l1.29-1.286 1.278 1.275c.882.88 1.311 1.292 1.386 1.33.077.039.164.057.304.065.174.01.21.004.336-.053a.964.964 0 00.209-.13c.087-.081 4.61-6.86 4.686-7.023.16-.342-.02-.799-.377-.966a.879.879 0 00-.484-.054\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
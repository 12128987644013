define("ember-svg-jar/inlined/suppression-notice-bounced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M37.2 37.127l12.237 12.238M37.2 49.362l12.237-12.237\" stroke=\"red\" stroke-width=\"2.3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M31.316 53.983l-7.99-7.956-8.58 4.433.334-12.717m0 0L5.033 27.697a4.033 4.033 0 01-1.1-3.667 4.106 4.106 0 012.713-3.15L45.98 7.757a4.106 4.106 0 015.28 5.276L45 30m-29.92 7.743l35.006-29.18\" stroke=\"#CAD1D8\" stroke-width=\"2.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 60 60"
    }
  };
});
define("ember-svg-jar/inlined/filter-dropdown-logins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.34 8.042h9.074M7.707 5.335l2.707 2.707-2.707 2.708\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M2.218 11.649a6.59 6.59 0 003.2 2.727 6.204 6.204 0 004.114.162 6.521 6.521 0 003.385-2.468 7.1 7.1 0 001.301-4.114 7.102 7.102 0 00-1.3-4.112 6.52 6.52 0 00-3.386-2.469 6.203 6.203 0 00-4.114.161 6.591 6.591 0 00-3.2 2.728\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
define("ember-svg-jar/inlined/filter-dropdown-clicked-in-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.207 10.267a.5.5 0 00-1 0v1.808H10.4a.5.5 0 100 1h1.807v1.807a.5.5 0 101 0v-1.807h1.808a.5.5 0 000-1h-1.808v-1.808z\" fill=\"currentColor\"/><path d=\"M8.666 14.485l-.93.462a.493.493 0 01-.673-.243l-1.45-3.32-3.024 1.5a.483.483 0 01-.65-.198.527.527 0 01-.067-.244L1.6 1.758a.555.555 0 01.302-.505.483.483 0 01.557.079l8.181 6.76c.061.057.107.13.134.211a.554.554 0 01-.08.493c-.052.07-5.081 2.589-.196.165\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
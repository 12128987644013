define("liquid-wormhole/templates/components/liquid-wormhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    {{#if this.hasSend}}
    {{#if (has-block)}}
      <this.sendComponent
        id={{this.wormholeId}}
        class="{{this.wormholeClass}} liquid-wormhole-element" as |details|
      >
        {{yield details}}
      </this.sendComponent>
    {{else}}
      <this.sendComponent
        id={{this.wormholeId}}
        class="{{this.wormholeClass}} liquid-wormhole-element"
      />
    {{/if}}
  {{else}}
    <div
      id={{this.wormholeId}}
      class="{{this.wormholeClass}} liquid-wormhole-element"
    >
      {{yield}}
    </div>
  {{/if}}
  */
  {
    "id": "FcVE2XL/",
    "block": "{\"symbols\":[\"details\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"hasSend\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,[32,0,[\"sendComponent\"]],[[16,1,[32,0,[\"wormholeId\"]]],[16,0,[31,[[32,0,[\"wormholeClass\"]],\" liquid-wormhole-element\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,2,[[32,1]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,0,[\"sendComponent\"]],[[16,1,[32,0,[\"wormholeId\"]]],[16,0,[31,[[32,0,[\"wormholeClass\"]],\" liquid-wormhole-element\"]]]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,1,[32,0,[\"wormholeId\"]]],[15,0,[31,[[32,0,[\"wormholeClass\"]],\" liquid-wormhole-element\"]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "liquid-wormhole/templates/components/liquid-wormhole.hbs"
  });
});
define("ember-svg-jar/inlined/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.166 6.667a.5.5 0 001 0A3.167 3.167 0 008 3.5a.5.5 0 000 1c1.196 0 2.166.97 2.166 2.167z\" fill=\"#113139\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.333 6.667c0-1.241 0-1.862.163-2.364a3.333 3.333 0 012.14-2.14C6.138 2 6.76 2 8 2c1.24 0 1.861 0 2.363.163a3.333 3.333 0 012.14 2.14c.163.502.163 1.123.163 2.364v4.925c0 1.278 0 1.917-.228 2.255a1.333 1.333 0 01-1.258.579c-.405-.047-.89-.463-1.86-1.295-.428-.366-.642-.55-.876-.632a1.334 1.334 0 00-.889 0c-.234.083-.448.266-.875.632-.97.832-1.455 1.248-1.86 1.294a1.333 1.333 0 01-1.259-.578c-.228-.338-.228-.977-.228-2.255V6.667zm8.22-2.055c.101.314.113.736.113 2.055v4.925c0 .656 0 1.088-.025 1.401-.016.204-.04.29-.046.314a.333.333 0 01-.277.127 1.675 1.675 0 01-.267-.17c-.254-.184-.583-.465-1.081-.892l-.087-.075c-.334-.288-.688-.593-1.106-.74a2.333 2.333 0 00-1.555 0c-.417.147-.771.452-1.106.74a3.109 3.109 0 00-.022.019l-.065.056c-.498.427-.827.708-1.08.893-.165.12-.246.159-.268.17a.333.333 0 01-.277-.128 1.665 1.665 0 01-.046-.314c-.024-.313-.025-.745-.025-1.401V6.667c0-1.319.012-1.741.114-2.055.231-.71.788-1.267 1.498-1.498C6.26 3.012 6.681 3 8 3c1.318 0 1.74.012 2.054.114.71.231 1.267.788 1.498 1.498z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
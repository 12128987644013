define("ember-svg-jar/inlined/calendar-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-stroke</title><defs><style>.calendar-stroke_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><rect class=\"calendar-stroke_svg__a\" x=\".752\" y=\"3.75\" width=\"22.5\" height=\"19.5\" rx=\"1.5\" ry=\"1.5\"/><path class=\"calendar-stroke_svg__a\" d=\"M.752 9.75h22.5M6.752 6V.75M17.252 6V.75\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("@tryghost/ember-promise-modals/modal", ["exports", "@ember/test-waiters"], function (_exports, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
   * @typedef {Object} ModalOptions
   * @property {Function} [onAnimationModalOutEnd]
   * @property {String} [className] - CSS class name passed to the modal element
   */
  /**
   * @class Modal
   * @param {Service} service
   * @param {String} name
   * @param {any} data
   * @param {ModalOptions} options
   * @method close - closes the modal
   * @method then - resolves when the modal is closed
   */
  let Modal = _exports.default = (_dec = Ember.computed('_deferredOutAnimation'), (_class = class Modal {
    constructor(service, name, data, options = {}) {
      this._service = service;
      this._name = name;
      this._data = data;
      this._options = {
        className: '',
        onAnimationModalOutEnd: undefined,
        ...options
      };
      this._result = undefined;
      this._deferred = Ember.RSVP.defer();
      this._deferredOutAnimation = undefined;
      this._componentInstance = undefined;
    }
    get result() {
      return this._result;
    }
    get isClosing() {
      return Boolean(this._deferredOutAnimation);
    }
    close(result) {
      if (this._componentInstance) {
        this._componentInstance.closeModal(result);
      }
    }
    then(onFulfilled, onRejected) {
      return this._deferred.promise.then(onFulfilled, onRejected);
    }
    finally(callback) {
      return this._deferred.promise.finally(callback);
    }
    _resolve(result) {
      if (!this._deferredOutAnimation) {
        Ember.set(this, '_deferredOutAnimation', Ember.RSVP.defer());
        if (this._options.onAnimationModalOutEnd) {
          this._deferredOutAnimation.promise.then(() => this._options.onAnimationModalOutEnd()).catch(() => {});
        }
        this._result = result;
        this._deferred.resolve(result);
        (0, _testWaiters.waitForPromise)(this._deferredOutAnimation.promise);
      }
    }
    _remove() {
      this._service._stack.removeObject(this);
      if (this._service._stack.length === 0) {
        this._service._onLastModalRemoved();
      }
      this._componentInstance = undefined;
      if (this._deferredOutAnimation) {
        this._deferredOutAnimation.resolve();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "isClosing", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isClosing"), _class.prototype)), _class));
});
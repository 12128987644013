define("ember-svg-jar/inlined/rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Space Rocket Flying Streamline Icon: https://streamlinehq.com</desc><path d=\"M22.5 1.567l-2.158.24a7.5 7.5 0 00-4.475 2.151L6.06 13.765l4.24 4.242L20.109 8.2a7.494 7.494 0 002.151-4.475zM6.06 13.765l-3.622-1.208a.749.749 0 01-.293-1.241l.232-.232a6 6 0 016.14-1.45l1.255.418z\" fill=\"none\" stroke=\"currentcolor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/><path d=\"M6.06 13.765l-3.622-1.208a.749.749 0 01-.293-1.241l.232-.232a6 6 0 016.14-1.45l1.255.418zM10.3 18.007l1.208 3.622a.749.749 0 001.241.293l.233-.232a6 6 0 001.449-6.14l-.416-1.25z\" fill=\"none\" stroke=\"currentcolor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/><path d=\"M10.3 18.007l1.208 3.622a.749.749 0 001.241.293l.233-.232a6 6 0 001.449-6.14l-.416-1.25zM7.779 18.538a2.243 2.243 0 01-.659 1.591c-.878.878-5.3 2.121-5.3 2.121s1.243-4.425 2.121-5.3a2.246 2.246 0 011.444-.655\" fill=\"none\" stroke=\"currentcolor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
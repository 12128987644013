define("@tryghost/ember-promise-modals/components/modal", ["exports", "focus-trap", "@tryghost/ember-promise-modals/templates/components/modal"], function (_exports, _focusTrap, _modal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _modal.default,
    tagName: '',
    outAnimationClass: 'epm-out',
    modals: Ember.inject.service(),
    optionsClassName: Ember.computed.readOnly('modal._options.className'),
    modalElementId: null,
    focusTrapOptions: null,
    init() {
      this._super(...arguments);
      Ember.set(this, 'modalElementId', Ember.guidFor(this));
      this.modal._componentInstance = this;
      let {
        focusTrapOptions: globalFocusTrapOptions
      } = this.modals;
      let {
        focusTrapOptions: localFocusTrapOptions
      } = this.modal._options;
      if (localFocusTrapOptions !== null) {
        this.focusTrapOptions = localFocusTrapOptions || globalFocusTrapOptions;
      }
    },
    didInsertElement() {
      this._super(...arguments);
      let element = document.getElementById(this.modalElementId);
      if (this.focusTrapOptions) {
        let options = {
          ...this.focusTrapOptions,
          fallbackFocus: `#${this.modalElementId}`,
          onDeactivate: () => {
            this.focusTrapOptions.onDeactivate?.();
            if (this.isDestroyed || this.isDestroying) {
              return;
            }
            this.closeModal();
          }
        };
        this.focusTrap = (0, _focusTrap.createFocusTrap)(element, options);
        this.focusTrap.activate();
      }
      this.fadeOutEnd = ({
        target,
        animationName
      }) => {
        this.modals._onModalAnimationEnd();
        let isntTarget = target !== element;
        let animationEndsWrong = animationName.substring(animationName.length - 4) !== '-out';
        if (isntTarget || animationEndsWrong) {
          return;
        }
        this.modal._remove();
      };
      this.modals._onModalAnimationStart();
      element.addEventListener('animationend', this.fadeOutEnd);
      Ember.set(this, 'animatingOutClass', '');
    },
    willDestroyElement() {
      if (this.focusTrap) {
        this.focusTrap.deactivate({
          onDeactivate: null
        });
      }
      if (this.fadeOutEnd) {
        let element = document.getElementById(this.modalElementId);
        if (element) {
          element.removeEventListener('animationend', this.fadeOutEnd);
          // make sure that we remove the modal, also resolving the test waiter
          this.modal._remove();
        }
      }
      this._super(...arguments);
    },
    async closeModal(result) {
      if (this.modal._options.beforeClose) {
        let allowClose = await this.modal._options.beforeClose(result);
        if (allowClose === false) {
          return;
        }
      }

      // Trigger out animation
      Ember.set(this, 'animatingOutClass', this.outAnimationClass);
      if (this.focusTrap) {
        this.focusTrap.deactivate({
          onDeactivate: null
        });
      }
      this.modal._resolve(result);
    },
    actions: {
      close(result) {
        this.closeModal(result);
      }
    }
  });
});
define("ember-svg-jar/inlined/glitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.53 1.569a.368.368 0 01.664 0l.687 1.43a.368.368 0 00.172.172l1.43.687a.368.368 0 010 .663l-1.43.687a.368.368 0 00-.172.172l-.687 1.43a.368.368 0 01-.663 0l-.687-1.43a.368.368 0 00-.173-.172l-1.43-.687a.368.368 0 010-.663l1.43-.687a.368.368 0 00.173-.172l.687-1.43zM9.985 4.12a.552.552 0 01.994 0l1.21 2.518a.552.552 0 00.258.258l2.517 1.21c.417.2.417.794 0 .994l-2.517 1.21a.552.552 0 00-.258.258l-1.21 2.517a.552.552 0 01-.994 0l-1.21-2.517a.552.552 0 00-.258-.258L6 9.1a.552.552 0 010-.994l2.517-1.21a.552.552 0 00.258-.258l1.21-2.517zM2.259 9.069a.248.248 0 01.448 0L3.25 10.2c.024.051.065.092.116.117l1.133.544a.248.248 0 010 .448l-1.133.544a.248.248 0 00-.116.116l-.544 1.133a.248.248 0 01-.448 0l-.544-1.133a.248.248 0 00-.117-.116L.466 11.31a.248.248 0 010-.448l1.132-.544a.248.248 0 00.117-.117l.544-1.132z\" fill=\"#fff\"/>",
    "attrs": {
      "style": "width:16px;height:13px",
      "viewBox": "0 0 16 15",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/confetti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>confetti</title><defs><style>.confetti_svg__cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><ellipse class=\"confetti_svg__cls-1\" cx=\"11.531\" cy=\"12.469\" rx=\"2.625\" ry=\"5.25\" transform=\"rotate(-45 11.531 12.47)\"/><path class=\"confetti_svg__cls-1\" d=\"M7.4 9.7L.888 21.121a1.5 1.5 0 001.991 1.991L14.3 16.605M15.773 7.7a.375.375 0 010 .531m-.53-.531a.375.375 0 01.53 0m-.53.527a.377.377 0 010-.531m.53.531a.375.375 0 01-.53 0m4.773-4.773a.374.374 0 010 .53m-.53-.53a.374.374 0 01.53 0m-.53.53a.374.374 0 010-.53m.53.53a.375.375 0 01-.53 0m.53 10.076a.375.375 0 010 .531m-.53-.531a.375.375 0 01.53 0m-.53.531a.375.375 0 010-.531m.53.531a.375.375 0 01-.53 0M8.349 4.514a.377.377 0 010 .531m-.53-.531a.375.375 0 01.53 0m-.53.531a.375.375 0 010-.531m.53.531a.375.375 0 01-.53 0M12.857.75a13.836 13.836 0 01-.531 5.62m4.243-4.242l-.53 2.651m7.211 6.364a13.836 13.836 0 00-5.62.531m4.242-4.243l-2.651.53\"/>",
    "attrs": {
      "id": "confetti_svg__Regular",
      "viewBox": "0 0 24 24"
    }
  };
});
define("liquid-wormhole/components/liquid-wormhole", ["exports", "@ember-decorators/component", "@embroider/util", "liquid-wormhole/templates/components/liquid-wormhole", "jquery"], function (_exports, _component, _util, _liquidWormhole, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2, _descriptor, _descriptor2;
  /* eslint-disable ember/require-tagless-components */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LiquidWormhole = _exports.default = (_dec = (0, _component.layout)(_liquidWormhole.default), _dec2 = Ember.inject.service('liquid-wormhole'), _dec3 = Ember._tracked, _dec(_class = (_class2 = class LiquidWormhole extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "liquidWormholeService", _descriptor, this);
      _initializerDefineProperty(this, "hasSend", _descriptor2, this);
      // Truthy value by default
      _defineProperty(this, "value", true);
    }
    get sendComponent() {
      if (this.send) {
        return (0, _util.ensureSafeComponent)(this.send, this);
      }
      return null;
    }
    get to() {
      return this.destination;
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      const wormholeClass = this.class;
      const wormholeId = this.stack || this.id;
      Ember.set(this, 'stack', Ember.guidFor(this));
      Ember.set(this, 'wormholeClass', wormholeClass);
      Ember.set(this, 'wormholeId', wormholeId);
      if (Ember.typeOf(this.send) !== 'function') {
        Ember.set(this, 'hasSend', true);
      }
      super.init(...arguments);
    }
    didUpdateAttrs() {
      super.didUpdateAttrs(...arguments);
      this.liquidWormholeService.removeWormhole(this, this.to);
      this.liquidWormholeService.appendWormhole(this, this.to);
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      const nodes = (0, _jquery.default)(this.element).children();
      Ember.set(this, 'nodes', nodes);
      this.element.className = 'liquid-wormhole-container';
      this.element.id = '';
      this.liquidWormholeService.appendWormhole(this, this.to);
      super.didInsertElement.apply(this, arguments);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.liquidWormholeService.removeWormhole(this, this.to);
      super.willDestroyElement.apply(this, arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "liquidWormholeService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hasSend", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class);
});
define("ember-svg-jar/inlined/filter-dropdown-signups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.7 12.299a6.573 6.573 0 018.135-2.65M7.314 7.172a3.286 3.286 0 100-6.573 3.286 3.286 0 000 6.573v0zm4.84 3.021v5.294m-2.648-2.646H14.8\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
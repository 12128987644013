define("ember-svg-jar/inlined/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.75 11.333a1.75 1.75 0 00-3.5 0v23.334a1.75 1.75 0 103.5 0V11.333zM34.083 20.667a1.75 1.75 0 10-3.5 0v14a1.75 1.75 0 103.5 0v-14zM15.417 25.333a1.75 1.75 0 10-3.5 0v9.334a1.75 1.75 0 103.5 0v-9.334z\" fill=\"#EF5A2B\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M33.415.539C30.75.25 27.39.25 23.105.25h-.21c-4.286 0-7.645 0-10.31.289-2.72.294-4.923.906-6.804 2.273a13.417 13.417 0 00-2.969 2.969C1.445 7.662.833 9.866.54 12.585.25 15.25.25 18.61.25 22.895v.21c0 4.286 0 7.645.289 10.31.294 2.72.906 4.923 2.273 6.804a13.415 13.415 0 002.969 2.969c1.881 1.367 4.085 1.979 6.804 2.273 2.665.289 6.024.289 10.31.289h.21c4.286 0 7.645 0 10.31-.289 2.72-.294 4.923-.906 6.804-2.273a13.415 13.415 0 002.969-2.969c1.367-1.881 1.979-4.084 2.273-6.804.289-2.665.289-6.024.289-10.31v-.21c0-4.286 0-7.645-.289-10.31-.294-2.72-.906-4.923-2.273-6.804a13.415 13.415 0 00-2.969-2.969C38.338 1.445 36.135.833 33.415.54zM7.838 5.644c1.185-.861 2.702-1.363 5.124-1.626C15.417 3.752 18.586 3.75 23 3.75c4.414 0 7.583.002 10.038.268 2.422.263 3.939.765 5.124 1.626a9.917 9.917 0 012.194 2.194c.861 1.185 1.363 2.702 1.626 5.124.266 2.455.268 5.624.268 10.038 0 4.414-.002 7.583-.268 10.038-.263 2.422-.765 3.939-1.626 5.124a9.918 9.918 0 01-2.194 2.194c-1.185.861-2.702 1.363-5.124 1.626-2.455.266-5.624.268-10.038.268-4.414 0-7.583-.002-10.038-.268-2.422-.263-3.939-.765-5.124-1.626a9.917 9.917 0 01-2.194-2.194c-.861-1.185-1.363-2.702-1.626-5.124C3.752 30.583 3.75 27.414 3.75 23c0-4.414.002-7.583.268-10.038.263-2.422.765-3.939 1.626-5.124a9.916 9.916 0 012.194-2.194z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 46 46",
      "fill": "currentColor"
    }
  };
});
define("ember-svg-jar/inlined/cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"13\" cy=\"13\" r=\"13\" fill=\"#EF5A2B\"/><path d=\"M18.187 8.854a.5.5 0 10-.707-.708l-4.313 4.314-4.313-4.314a.5.5 0 10-.708.708l4.314 4.313-4.314 4.313a.5.5 0 10.708.707l4.313-4.313 4.313 4.313a.5.5 0 10.707-.707l-4.313-4.313 4.313-4.313z\" fill=\"#fff\"/>",
    "attrs": {
      "style": "width:26px;height:26px",
      "viewBox": "0 0 26 26",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/event-made-a-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9 6.129v-3m-3 11c0 1.5 1.34 2 3 2s3 0 3-2c0-3-6-3-6-6 0-2 1.34-2 3-2s3 .76 3 2m-3 8v3\" stroke=\"#6C747D\" stroke-width=\"1.714\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16.338 15.206v6.923m-3.461-3.461H19.8\" stroke=\"#30CF43\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/empty-opened", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#empty-opened_svg__clip0_127_11405)\" stroke=\"#CFD4D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M36.31 19.333a2.857 2.857 0 000-3.825C33.313 12.28 26.49 6 18.522 6 10.556 6 3.733 12.28.736 15.508a2.857 2.857 0 000 3.825c2.997 3.227 9.82 9.508 17.788 9.508.526 0 .156-.027.669-.079\"/><path d=\"M18.522 23.134a5.711 5.711 0 100-11.423 5.712 5.712 0 000 11.42v.003zM32.271 21.622v15.451m-7.722-7.726h15.45\"/></g><defs><clipPath id=\"empty-opened_svg__clip0_127_11405\"><path fill=\"#fff\" d=\"M0 0h40v40H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/feature-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feature-image</title><path d=\"M21.01 2.253h-18a1.5 1.5 0 00-1.5 1.5v16.5a1.5 1.5 0 001.5 1.5h18a1.5 1.5 0 001.5-1.5v-16.5a1.5 1.5 0 00-1.5-1.5zm-19.5 3.5h21\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5 18.5L8.5 14l2.5 2 3.8-4.5 4.2 7H5zm5.25-6.5a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/view-site", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 11.167a.5.5 0 100-1H8a.5.5 0 100 1h4z\" fill=\"#113139\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.23 1.547c-.576-.047-1.293-.047-2.207-.047H5.978c-.915 0-1.631 0-2.208.047-.586.048-1.071.147-1.51.37A3.833 3.833 0 00.585 3.594c-.224.439-.323.924-.37 1.51C.166 5.68.166 6.396.166 7.311v.045c0 .914 0 1.631.047 2.207.048.587.147 1.072.37 1.51A3.834 3.834 0 002.26 12.75c.439.223.924.323 1.51.37.577.048 1.293.048 2.208.048H7.5v1.222c-.416.03-.83.095-1.238.197l-1.05.262a.5.5 0 10.243.97l1.05-.262a6.164 6.164 0 012.991 0l1.05.262a.5.5 0 00.242-.97l-1.05-.262a7.166 7.166 0 00-1.238-.197v-1.222h1.523c.914 0 1.631 0 2.207-.047.587-.048 1.072-.148 1.51-.371a3.834 3.834 0 001.676-1.675c.223-.44.323-.924.37-1.51.048-.577.048-1.294.048-2.208V7.31c0-.915 0-1.631-.047-2.208-.048-.586-.148-1.071-.371-1.51a3.834 3.834 0 00-1.675-1.675c-.44-.224-.924-.323-1.51-.37zm-.081 10.576c-.53.043-1.207.044-2.149.044H6c-.941 0-1.618 0-2.148-.044-.525-.043-.864-.125-1.138-.265a2.833 2.833 0 01-1.238-1.238c-.14-.275-.222-.613-.265-1.138-.044-.53-.044-1.207-.044-2.149 0-.941 0-1.618.044-2.148.043-.525.125-.864.265-1.138a2.833 2.833 0 011.238-1.238c.274-.14.613-.222 1.138-.265C4.382 2.5 5.059 2.5 6 2.5h4c.942 0 1.618 0 2.149.044.525.043.863.125 1.138.265.533.271.966.705 1.238 1.238.14.274.222.613.265 1.138.043.53.044 1.207.044 2.148 0 .942 0 1.618-.044 2.149-.043.525-.125.863-.265 1.138a2.833 2.833 0 01-1.238 1.238c-.275.14-.613.222-1.138.265z\"/><path d=\"M5.334 10.667a.667.667 0 11-1.334 0 .667.667 0 011.334 0z\" fill=\"#113139\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "currentColor"
    }
  };
});
define("ember-svg-jar/inlined/labs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>labs</title><defs><style>.labs_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"labs_svg__a\" d=\"M6.726.75h10.5m-1.5 7.5V.75h-7.5v7.5L1.489 18.615A3 3 0 004 23.25h15.948a3 3 0 002.515-4.635zm-10.425 4.5h13.35m-4.425 4.5h3m-1.5-1.5v3\"/><path class=\"labs_svg__a\" d=\"M6.726 19.125a.375.375 0 01.374.375m-.749 0a.375.375 0 01.375-.375m0 .75a.375.375 0 01-.375-.375\"/><path class=\"labs_svg__a\" d=\"M7.1 19.5a.375.375 0 01-.375.375m3.001-3.75a.375.375 0 01.375.375m-.75 0a.375.375 0 01.375-.375m0 .75a.375.375 0 01-.375-.375\"/><path class=\"labs_svg__a\" d=\"M10.1 16.5a.375.375 0 01-.375.375M15.726 3.75h-3m3 3h-3\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("perf-primitives/-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UNDEFINED_KEY = _exports.SMALL_ARRAY_LENGTH = _exports.LARGE_ARRAY_LENGTH = void 0;
  const SMALL_ARRAY_LENGTH = _exports.SMALL_ARRAY_LENGTH = 200;
  const LARGE_ARRAY_LENGTH = _exports.LARGE_ARRAY_LENGTH = 64000;
  const UNDEFINED_KEY = _exports.UNDEFINED_KEY = Object.create(null);
});
define("ember-svg-jar/inlined/analytics-tab-conversions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.692 8.029a3.394 3.394 0 11-2.597-6.27 3.394 3.394 0 012.597 6.27zM2 14.202a6.998 6.998 0 018.853-3.17 7 7 0 013.496 3.17\" stroke=\"#7E8B99\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
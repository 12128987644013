define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.5 2.667a.5.5 0 10-1 0V7.5H2.667a.5.5 0 100 1H7.5v4.833a.5.5 0 001 0V8.5h4.834a.5.5 0 000-1H8.5V2.667z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "style": "width:16px;height:16px",
      "fill": "currentColor"
    }
  };
});
define("ember-svg-jar/inlined/members-segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>members-segment</title><path d=\"M3.5 24a.502.502 0 01-.498-.458L2.54 18H.5a.5.5 0 01-.5-.5V14a5.497 5.497 0 013.724-5.208 5.514 5.514 0 011.781-.297c.841 0 1.655.19 2.42.566a5.467 5.467 0 012.781 3.16.497.497 0 01-.311.635.5.5 0 01-.635-.312 4.472 4.472 0 00-2.275-2.586 4.461 4.461 0 00-3.438-.219A4.497 4.497 0 001 14v3h2c.258 0 .477.201.498.458L3.96 23h3.08l.462-5.542A.502.502 0 018 17h1a.5.5 0 010 1h-.54l-.462 5.542A.502.502 0 017.5 24h-4zm2-16c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-7c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm12 23c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.467-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.467 5.5-5.5-2.467-5.5-5.5-5.5z\"/><path d=\"M16.667 20.428a1.24 1.24 0 01-.882-.365l-1.5-1.5a.5.5 0 01.708-.707l1.5 1.5a.244.244 0 00.176.072.252.252 0 00.202-.1l2.905-3.872a.501.501 0 01.8.6l-2.905 3.872c-.237.314-.612.5-1.004.5z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
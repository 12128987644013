define("ember-svg-jar/inlined/event-opened-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.27 12.661a1.28 1.28 0 000-1.714c-1.343-1.446-4.4-4.26-7.97-4.26-3.57 0-6.627 2.814-7.97 4.26a1.28 1.28 0 000 1.714c1.343 1.446 4.4 4.26 7.97 4.26.236 0 .07-.012.3-.035\" stroke=\"#6C747D\" stroke-width=\"1.55\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M12.3 14.364a2.559 2.559 0 100-5.118 2.559 2.559 0 000 5.117z\" stroke=\"#6C747D\" stroke-width=\"1.55\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M18.46 13.687v6.923M15 17.148h6.923\" stroke=\"#30CF43\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/member", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>member</title><path d=\"M22 20.908a3.99 3.99 0 00-2.934-3.856C17.172 16.535 14.586 16 12 16c-2.586 0-5.172.535-7.066 1.052A3.99 3.99 0 002 20.908V23h20v-2.092zM12 13c.722 0 1.438-.155 2.105-.457a5.496 5.496 0 001.784-1.3 6.062 6.062 0 001.192-1.947A6.477 6.477 0 0017.5 7c0-.788-.142-1.568-.419-2.296a6.063 6.063 0 00-1.192-1.947 5.481 5.481 0 00-1.784-1.3A5.105 5.105 0 0012 1c-1.459 0-2.858.632-3.89 1.757C7.08 3.883 6.5 5.41 6.5 7c0 1.591.58 3.117 1.61 4.243C9.143 12.368 10.542 13 12 13z\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/event-less-like-this", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 9.239a99.532 99.532 0 00-.731-.372c-4.505-2.275-4.284-1.832-8.141-1.832-1.61 0-2.43 1.047-2.905 2.581v.01l-1.17 3.905v.008a1.222 1.222 0 001.174 1.577h3.01a1.24 1.24 0 01.972.475 1.215 1.215 0 01.214 1.057l-.541 2.042a1.037 1.037 0 00.58 1.216 1.054 1.054 0 001.295-.35l3.001-4.611a1.237 1.237 0 011.003-.516H18M18 15.666V7\" stroke=\"#6D747C\" stroke-width=\"1.55\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
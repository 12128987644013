define("ember-svg-jar/inlined/event-sent-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.835 16.242l2.414 2.404a1.22 1.22 0 001.163.332 1.24 1.24 0 00.898-.82l3.965-11.885a1.24 1.24 0 00-1.595-1.595L5.795 8.643a1.24 1.24 0 00-.82.953 1.218 1.218 0 00.332 1.108l3.035 3.035-.1 3.843 2.593-1.34zm8.082-11.316L8.339 13.743\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
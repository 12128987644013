define("ember-svg-jar/inlined/portal-icon-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>portal-icon-2</title><path stroke=\"#FFF\" stroke-width=\"1.5\" stroke-linecap=\"round\" d=\"M12.5 2v20M2 12.5h20\" fill=\"none\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
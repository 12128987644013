define("ember-svg-jar/inlined/unmute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>unmute</title><path d=\"M15.189 2.021a9.728 9.728 0 00-7.924 4.85.249.249 0 01-.221.133H5.25a3 3 0 00-3 3v2a3 3 0 003 3h1.794a.249.249 0 01.221.133 9.73 9.73 0 007.924 4.85h.06a1 1 0 001-1V3.02a1 1 0 00-1.06-.998z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/arrow-down-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.47 3a2 2 0 00-1.74-1H2.27A2 2 0 00.56 5l9.73 16.07a2 2 0 003.42 0L23.44 5a2 2 0 00.03-2z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
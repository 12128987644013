define("ember-svg-jar/inlined/empty-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.002 31.697c.647.332 1.297.662 1.948.99 12.013 6.069 11.425 4.887 21.71 4.887 4.293 0 6.476-2.793 7.745-6.884v-.027l3.12-10.413v-.02a3.26 3.26 0 00-3.13-4.206h-8.026a3.304 3.304 0 01-2.593-1.267 3.239 3.239 0 01-.571-2.82l1.443-5.444a2.767 2.767 0 00-.532-2.46 2.81 2.81 0 00-4.468.149L11.645 16.48a3.301 3.301 0 01-2.675 1.377H3.002M3 14.556v23.11\" stroke=\"#CFD4D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  };
});
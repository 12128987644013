define("ember-svg-jar/inlined/posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.151.833c-.429 0-.784.18-1.12.436-.318.243-.67.596-1.094 1.02L5.283 5.943c-.28.28-.503.501-.639.784-.136.283-.17.595-.214.99l-.011.106c-.082.731-.149 1.334-.133 1.809.016.497.123.952.479 1.307.355.356.81.463 1.307.48.475.015 1.078-.053 1.81-.134l.106-.011c.394-.044.705-.078.988-.214.284-.136.505-.358.785-.639l3.654-3.654c.424-.423.777-.777 1.02-1.095.256-.335.435-.69.435-1.12 0-.429-.18-.784-.435-1.12-.243-.317-.596-.67-1.02-1.094l-.049-.05c-.423-.423-.777-.776-1.095-1.019-.335-.256-.69-.436-1.12-.436zm1.885 4.897L9.974 2.668c.27-.267.48-.463.664-.604.247-.188.39-.23.513-.23s.267.042.514.23c.258.197.564.502 1.019.956.454.455.758.76.956 1.02.188.246.23.39.23.512 0 .123-.042.267-.23.514a7.49 7.49 0 01-.604.664zm-3.77-2.356l3.064 3.064-3.228 3.228c-.351.351-.449.44-.559.492-.11.053-.24.074-.733.13-.78.086-1.311.143-1.706.13-.38-.012-.535-.088-.632-.186-.098-.097-.175-.251-.187-.632-.013-.395.045-.926.132-1.706.055-.494.076-.623.129-.733.053-.11.14-.208.492-.56l3.228-3.227z\"/><path d=\"M6.006 2.62a.5.5 0 00-.012-1c-1.618.019-2.728.107-3.58.726a3.833 3.833 0 00-.849.849c-.39.537-.565 1.167-.65 1.944C.833 5.9.833 6.859.833 8.084v.06c0 1.225 0 2.185.082 2.946.085.777.26 1.407.65 1.944.237.326.523.612.848.848.538.39 1.167.566 1.944.65.762.082 1.721.082 2.946.082h.06c1.225 0 2.184 0 2.946-.082.777-.084 1.406-.26 1.944-.65.325-.236.611-.522.848-.848.62-.852.708-1.963.727-3.58a.5.5 0 00-1-.012c-.02 1.667-.132 2.448-.536 3.004-.175.241-.386.453-.627.627-.339.246-.772.39-1.464.465-.701.076-1.607.076-2.868.076s-2.167 0-2.868-.076c-.692-.075-1.125-.219-1.464-.465a2.833 2.833 0 01-.627-.627c-.246-.338-.39-.772-.464-1.464-.076-.701-.077-1.607-.077-2.868 0-1.26 0-2.166.077-2.868.075-.692.218-1.125.464-1.464.175-.24.386-.452.627-.627.556-.404 1.337-.516 3.005-.536z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "currentColor"
    }
  };
});
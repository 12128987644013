define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 1a.5.5 0 00-1 0v8.54c-.215-.218-.47-.54-.843-1.009l-.932-1.175a.5.5 0 00-.783.621l.95 1.2c.356.448.65.818.912 1.081.27.27.568.495.95.556a1.558 1.558 0 00.492 0c.382-.061.68-.285.95-.556.263-.263.556-.633.912-1.081l.95-1.2a.5.5 0 00-.783-.621l-.932 1.175c-.372.47-.628.791-.843 1.01V1z\" fill=\"#fff\"/><path d=\"M12.733 6.033a.5.5 0 00-.8.6c.357.474.567 1.062.567 1.7V9c0 .638-.002.9-.035 1.11a2.833 2.833 0 01-2.355 2.355c-.21.033-.472.035-1.11.035H5c-.638 0-.9-.002-1.11-.035a2.833 2.833 0 01-2.355-2.355C1.502 9.9 1.5 9.638 1.5 9v-.667c0-.638.21-1.226.566-1.7a.5.5 0 10-.8-.6 3.819 3.819 0 00-.766 2.3v.72c0 .569 0 .915.047 1.213a3.833 3.833 0 003.187 3.187c.298.047.644.047 1.212.047h4.108c.568 0 .914 0 1.212-.047a3.833 3.833 0 003.187-3.187c.047-.298.047-.644.047-1.212v-.72c0-.863-.285-1.66-.767-2.301z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 14 14",
      "fill": "none"
    }
  };
});
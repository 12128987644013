define("ember-svg-jar/inlined/event-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.996 5C7.858 5 4.5 7.804 4.5 11.265c.02.876.234 1.735.625 2.51a5.63 5.63 0 001.624 1.962 2.255 2.255 0 01-.204 1.676c-.14.25-.327.47-.55.643a2.032 2.032 0 01-.745.367s-.24.322.352.439c2.391.454 4.333-1.566 4.333-1.566v-.04a8.909 8.909 0 002.069.243c4.138 0 7.496-2.803 7.496-6.265C19.5 7.772 16.134 5 11.996 5z\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
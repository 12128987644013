define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.135.884c-.518-.3-.951-.55-1.321-.702-.39-.16-.78-.242-1.195-.13-.414.11-.71.376-.969.71-.244.317-.494.75-.793 1.268L1.273 6.505c-.174.3-.316.546-.387.81a1.47 1.47 0 00-.027.113c-.058.31-.01.619.05 1.01l.016.107c.11.727.202 1.327.34 1.782.144.476.365.887.8 1.139.436.251.904.237 1.387.124.463-.108 1.028-.329 1.714-.597l.1-.039c.369-.144.661-.258.9-.462.237-.205.394-.477.592-.82l2.584-4.475c.3-.519.549-.952.701-1.322.16-.39.242-.78.131-1.194-.111-.415-.376-.711-.71-.969-.317-.244-.75-.494-1.269-.794l-.06-.034zm.073 4.276L4.456 2.993 2.173 6.946c-.248.43-.308.547-.33.668-.023.12-.01.25.065.742.118.776.2 1.303.314 1.681.11.365.224.494.344.563.12.069.288.103.659.016.384-.09.882-.283 1.613-.568.463-.181.583-.235.676-.315.092-.08.163-.19.412-.62L8.208 5.16zm.5-.867c.187-.33.322-.583.41-.798.118-.287.122-.437.09-.555-.032-.119-.11-.247-.356-.436-.257-.199-.63-.416-1.187-.737-.556-.321-.93-.536-1.231-.66-.287-.118-.437-.122-.556-.09-.118.032-.246.11-.436.356a7.5 7.5 0 00-.485.755l3.75 2.165z\"/>",
    "attrs": {
      "viewBox": "0 0 11 12",
      "fill": "#fff"
    }
  };
});
define("ember-svg-jar/inlined/filter-dropdown-email-flagged-as-spam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.351 14.396l-2.13-2.122-2.289 1.182.09-3.391m0 0l-2.68-2.68a1.075 1.075 0 01-.293-.977 1.095 1.095 0 01.723-.84l10.49-3.5a1.095 1.095 0 011.407 1.408L12 8m-7.979 2.065l9.335-7.781m-2 8.381v1.912\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"11.334\" cy=\"14.434\" r=\".667\" fill=\"currentColor\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
define("ember-svg-jar/inlined/check-circle-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-circle-stroke</title><defs><style>.check-circle-stroke_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"check-circle-stroke_svg__a\" d=\"M6.75 9l3.294 4.611a1.526 1.526 0 002.414.09L23.25.749M16.783 3.824A10.487 10.487 0 1020.8 8.377\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/filter-dropdown-email-address-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M10.736 4.806v5.058c0 1.56 2.371 1.907 3.613-.203 1.052-1.785.794-4.509-.52-6.302C11.894.722 7.422-.265 4.173 1.916 1.19 3.92.127 7.975 1.776 11.252c1.632 3.243 5.463 4.814 8.931 3.641\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M7.882 10.94c1.57 0 2.843-1.362 2.843-3.041 0-1.68-1.273-3.04-2.843-3.04S5.04 6.218 5.04 7.898c0 1.679 1.272 3.04 2.842 3.04z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
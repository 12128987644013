define("ember-svg-jar/inlined/event-unsubscribed-from-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.5 15.076h3.92\" stroke=\"#F50B23\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M13.7 17.071H6.154A1.154 1.154 0 015 15.917V7.84a1.154 1.154 0 011.154-1.153h11.538a1.154 1.154 0 011.154 1.153v4.193\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5 8.14l6.923 4.893 6.923-4.892\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
define("@tryghost/ember-promise-modals/components/modal-container", ["exports", "@tryghost/ember-promise-modals/templates/components/modal-container"], function (_exports, _modalContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _modalContainer.default,
    tagName: '',
    modals: Ember.inject.service()
  });
});
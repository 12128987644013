define("ember-svg-jar/inlined/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.688.834c-.63-.013-1.33.208-2.48.571l-.15.047c-.45.142-.727.23-.983.354-.53.259-.993.636-1.353 1.102-.175.226-.316.48-.546.891l-1.63 2.918c-.52.928-.837 1.495-.958 2.052a3.833 3.833 0 00.947 3.436c.39.417.952.741 1.873 1.273l.174.1c.922.533 1.484.857 2.04.987a3.833 3.833 0 003.448-.898c.422-.384.754-.942 1.3-1.856l1.71-2.87c.242-.406.391-.655.5-.919a3.834 3.834 0 00.277-1.723c-.02-.284-.083-.567-.185-1.028l-.034-.154c-.26-1.176-.42-1.893-.745-2.433A3.833 3.833 0 008.688.834zm-2.29 1.56c1.295-.409 1.826-.569 2.27-.56.972.02 1.866.535 2.369 1.367.23.38.356.92.65 2.246.114.513.158.72.172.922.03.435-.04.87-.205 1.274-.076.186-.184.37-.453.821l-1.64 2.748c-.612 1.028-.866 1.444-1.164 1.715a2.833 2.833 0 01-2.549.664c-.392-.091-.816-.33-1.853-.929-1.036-.598-1.456-.847-1.73-1.14a2.833 2.833 0 01-.7-2.54c.086-.393.319-.82.902-1.865l1.561-2.794c.257-.459.362-.644.485-.803a2.833 2.833 0 011-.815c.182-.088.384-.153.885-.311z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.504 4.804a1.667 1.667 0 102.887 1.667 1.667 1.667 0 00-2.887-1.667zm.867.5a.667.667 0 101.154.667.667.667 0 00-1.154-.667z\" fill=\"#113139\"/>",
    "attrs": {
      "viewBox": "0 0 13 15",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icon</title><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M80 160c44.183 0 80-35.817 80-80S124.183 0 80 0 0 35.817 0 80s35.817 80 80 80zM78.437 15.592C42.072 19.679 25.947 34.72 22.295 64.98c-.415 3.441-1.232 6.89-2.045 10.322-1.145 4.831-2.282 9.63-2.282 14.336 0 30.285 25.49 54.068 55.774 54.068 11.784 0 22.1-4.944 31.648-10.053 21.187-11.335 41.049-25.255 41.049-50.046 0-17.507-12.852-33.145-23.779-46.443a1336.4 1336.4 0 01-1.636-1.992c-5.56-6.79-25.248-21.53-42.587-19.58z\" fill=\"#15171A\"/>",
    "attrs": {
      "viewBox": "0 0 160 160",
      "fill": "none"
    }
  };
});
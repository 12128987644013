define("ember-svg-jar/inlined/house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.14.836a3.833 3.833 0 00-.282 0c-.618.023-1.174.233-1.785.583-.595.341-1.28.839-2.148 1.47l-.048.034c-.869.631-1.553 1.13-2.062 1.59-.521.472-.893.936-1.106 1.517a3.834 3.834 0 00-.087.268c-.169.595-.141 1.189.003 1.878.14.67.402 1.476.734 2.497l1.156 3.56a1.862 1.862 0 003.633-.576v-1.833c0-.43.348-.777.778-.777h2.146c.43 0 .778.348.778.777v1.833a1.862 1.862 0 003.633.575l1.156-3.559c.332-1.021.593-1.826.734-2.497.144-.689.172-1.283.003-1.878a3.852 3.852 0 00-.087-.268c-.213-.58-.585-1.045-1.106-1.518-.508-.46-1.193-.958-2.062-1.589l-.048-.034c-.868-.631-1.553-1.129-2.148-1.47-.61-.35-1.167-.56-1.785-.583zm-.245 1c.07-.003.139-.003.208 0 .397.014.792.145 1.325.45.542.312 1.184.777 2.081 1.429.898.652 1.539 1.119 2.002 1.539.455.411.702.747.839 1.12.024.065.045.13.064.198.109.382.106.798-.02 1.399-.128.612-.372 1.366-.715 2.421l-1.148 3.531a.862.862 0 01-1.68-.266v-1.833c0-.982-.797-1.777-1.779-1.777H6.926c-.982 0-1.778.795-1.778 1.777v1.833a.862.862 0 01-1.681.266l-1.148-3.53c-.343-1.056-.587-1.81-.715-2.422-.126-.6-.129-1.017-.02-1.4.02-.066.04-.132.064-.197.137-.373.384-.709.839-1.12.463-.42 1.104-.887 2.002-1.539.897-.652 1.539-1.117 2.082-1.429.532-.305.927-.436 1.324-.45z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/filter-dropdown-email-subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.408 13.316H2.613a1.08 1.08 0 01-1.08-1.08V4.68a1.08 1.08 0 011.08-1.08H13.41a1.08 1.08 0 011.08 1.08l.003 7.557\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M1.533 4.96l6.478 4.579L14.49 4.96\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
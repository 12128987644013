define("ember-svg-jar/inlined/edit-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>edit-view</title><g fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke=\"#000\" stroke-width=\"1.5\"><path d=\"M8.25 17.25h-6c-1 0-1.5-.5-1.5-1.5V2.55c0-.414.146-.768.44-1.06a1.44 1.44 0 011.06-.44h13.5c1 0 1.5.5 1.5 1.5v5.7\"/><path d=\"M4.2 11.8c.001-1.2.601-1.8 1.8-1.8 1.2 0 1.8.6 1.8 1.8.001 1.2-.599 1.8-1.798 1.8-1.2 0-1.8-.6-1.801-1.8zM8.5 6c0 1.2.6 1.8 1.8 1.8 1.2 0 1.8-.6 1.8-1.8 0-1.2-.6-1.8-1.8-1.8-.496 0-.92.175-1.273.527A1.736 1.736 0 008.5 6zm13.131 7.87L14 21.5l-3.75.75.75-3.75 7.631-7.63c.997-.997 1.994-.997 2.991 0l.009.009c.997.997.997 1.994 0 2.991zM7.8 11.8h4.4M8.5 6H4.2m9.6 0h-1.2\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/tribe-orb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#tribe-orb_svg__clip0_1558_11379)\" fill=\"#113139\"><path d=\"M11.713 3.903c6.465 0 11.712 5.248 11.712 11.713H11.713V3.903zM11.713 19.52C5.248 19.52 0 14.273 0 7.808h11.713V19.52z\"/><path opacity=\".5\" d=\"M19.52 11.712c0 6.465-5.247 11.713-11.712 11.713V11.712H19.52zM3.904 11.712C3.904 5.247 9.152 0 15.617 0v11.712H3.904z\"/></g><defs><clipPath id=\"tribe-orb_svg__clip0_1558_11379\"><path fill=\"#fff\" d=\"M0 0h23.425v23.425H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
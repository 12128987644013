define("ember-svg-jar/inlined/sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sun</title><defs><style>.sun_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><circle class=\"sun_svg__a\" cx=\"12\" cy=\"12\" r=\"4.5\"/><path class=\"sun_svg__a\" d=\"M12 .75V4.5m0 15v3.75M23.25 12H19.5m-15 0H.75m19.5-8.25l-3 3m-10.5 10.5l-3 3m16.5 0l-3-3M6.75 6.75l-3-3\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
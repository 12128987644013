define("ember-power-datepicker/components/power-datepicker", ["exports", "ember-power-datepicker/templates/components/power-datepicker", "@ember-decorators/component", "ember-power-datepicker/utils/computed-properties"], function (_exports, _powerDatepicker, _component, _computedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class2, _class3, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let _class = _exports.default = (_dec = (0, _component.layout)(_powerDatepicker.default), _dec2 = (0, _component.tagName)(''), _dec3 = (0, _computedProperties.fallbackAction)(function (day) {
    Ember.set(this, 'center', day.date);
  }), _dec4 = Ember._action, _dec(_class2 = _dec2(_class2 = (_class3 = class _class3 extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "closeOnSelect", true);
      _defineProperty(this, "format", 'YYYY/MM/DD');
      _initializerDefineProperty(this, "onCenterChange", _descriptor, this);
    }
    // Actions
    handleSelect(day, datepicker, e) {
      let {
        onSelect,
        closeOnSelect
      } = this;
      let value = onSelect(day, datepicker, e);
      if (value === false || !closeOnSelect) {
        return;
      }
      datepicker.actions.close();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "onCenterChange", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "handleSelect", [_dec4], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSelect"), _class3.prototype)), _class3)) || _class2) || _class2);
});
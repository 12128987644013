define("ember-svg-jar/inlined/default-favicon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>default-favicon</title><g clip-path=\"url(#default-favicon_svg__a)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M80 160c22.401 0 49.478-1.164 64-16 14.121-14.426 16-42.218 16-64 0-22.125-1.484-49.518-16-64C129.528 1.562 102.057 0 80 0 57.933 0 30.473 1.55 16 16 1.495 30.48 0 57.885 0 80c0 22.387 1.18 49.48 16 64 14.429 14.137 42.204 16 64 16z\" fill=\"#15171A\"/><path d=\"M80.301 24.45C111.042 24.45 136 49.251 136 79.801s-24.958 55.353-55.699 55.353-55.699-24.803-55.699-55.353S49.56 24.45 80.302 24.45zm-43.96 43.67c2.784-22.93 15.08-34.327 42.808-37.424 13.22-1.477 28.233 9.692 32.472 14.837C120.188 55.929 131 68.302 131 82.236c0 18.786-15.144 29.334-31.3 37.924-7.28 3.871-15.146 7.618-24.131 7.618-23.092 0-42.528-18.022-42.528-40.971 0-6.1 2.537-12.408 3.3-18.686z\" fill=\"#fff\"/><path d=\"M80 140.124c33.689 0 61-27.141 61-60.62 0-33.481-27.311-60.622-61-60.622-33.69 0-61 27.141-61 60.621 0 33.48 27.31 60.621 61 60.621zm0-6.735c29.946 0 54.222-24.126 54.222-53.886S109.946 25.618 80 25.618 25.778 49.743 25.778 79.503c0 29.76 24.276 53.886 54.222 53.886z\" fill=\"#fff\"/></g><defs><clipPath id=\"default-favicon_svg__a\"><path fill=\"#fff\" d=\"M0 0h160v160H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 160 160",
      "fill": "none"
    }
  };
});
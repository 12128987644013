define("ember-svg-jar/inlined/filter-dropdown-email-received", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.351 14.396l-2.13-2.122-2.289 1.182.09-3.391-2.68-2.68a1.076 1.076 0 01-.293-.977 1.095 1.095 0 01.723-.84l10.49-3.5a1.095 1.095 0 011.407 1.408L11.79 9.11m1.567-6.827l-9.336 7.78\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9.915 12.222l1.62 1.62a.221.221 0 00.315 0l3.177-3.175\" stroke=\"currentColor\" stroke-linecap=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
define("ember-svg-jar/inlined/picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Picture Landscape Streamline Icon: https://streamlinehq.com</desc><title>picture</title><path d=\"M1.383.46h11.984s.922 0 .922.923v11.984s0 .922-.922.922H1.383s-.922 0-.922-.922V1.383s0-.922.922-.922\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.25\"/><path d=\"M3.227 10.602l2.127-2.837a.922.922 0 011.39-.1l.631.632 2.028-2.704a.922.922 0 011.475 0L12.52 7.78M2.766 3.918a1.152 1.152 0 102.304 0 1.152 1.152 0 10-2.304 0M.46 10.602h13.83\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.25\"/>",
    "attrs": {
      "viewBox": "-0.625 -0.625 16 16",
      "stroke": "currentColor"
    }
  };
});
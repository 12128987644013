define("ember-svg-jar/inlined/empty-sent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#empty-sent_svg__clip0_127_11571)\" stroke=\"#CFD4D9\" stroke-width=\"2\" stroke-linecap=\"round\"><path d=\"M19.976 36.806l-5.811-5.786-6.24 3.224.242-9.249L.86 17.69a2.934 2.934 0 01-.8-2.667 2.987 2.987 0 011.974-2.291L30.64 3.187a2.987 2.987 0 013.84 3.837l-5.127 15.37m4.273-18.62L8.166 24.994\" stroke-linejoin=\"round\"/><path d=\"M24.242 30.88l4.42 4.419a.606.606 0 00.858 0L40 24.817\"/></g><defs><clipPath id=\"empty-sent_svg__clip0_127_11571\"><path fill=\"#fff\" d=\"M0 0h40v40H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/event-extras-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.39 10.49l5.22 11.4a.68.68 0 001.22-.13l1.53-4.21L21.6 16a.69.69 0 00.12-1.26l-11.4-5.21a.71.71 0 00-.93.96v0zM16.5 7.834A7.113 7.113 0 107.834 16.5\" stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
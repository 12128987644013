define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload</title><path d=\"M12.001 15.75v-12m4.5 4.5l-4.5-4.5-4.5 4.5m15.75 7.5v1.5a3 3 0 01-3 3h-16.5a3 3 0 01-3-3v-1.5\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.2\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
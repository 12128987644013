define("ember-svg-jar/inlined/sparkle-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Reward Stars 3 Streamline Icon: https://streamlinehq.com</desc><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M17.66 1.143a.75.75 0 011.451 0c.33 1.262.693 2.037 1.235 2.61.546.577 1.344 1.027 2.705 1.484a.75.75 0 010 1.422c-1.36.457-2.16.907-2.705 1.484-.542.574-.904 1.348-1.235 2.61a.75.75 0 01-1.45 0c-.331-1.262-.694-2.036-1.236-2.61-.545-.577-1.344-1.027-2.705-1.484a.75.75 0 010-1.422c1.361-.457 2.16-.907 2.705-1.483.542-.574.905-1.35 1.235-2.61zM8.052 5.322a.75.75 0 011.451 0c.595 2.283 1.27 3.774 2.33 4.9 1.062 1.129 2.584 1.967 5.031 2.792a.75.75 0 010 1.422c-2.447.824-3.969 1.663-5.031 2.792-1.06 1.126-1.735 2.617-2.33 4.9a.75.75 0 01-1.451 0c-.596-2.283-1.27-3.774-2.33-4.9C4.659 16.099 3.138 15.26.69 14.436a.75.75 0 010-1.422c2.448-.825 3.97-1.663 5.032-2.792 1.06-1.126 1.734-2.617 2.33-4.9zm11.85 10.147a.75.75 0 00-.725.556c-.24.9-.497 1.421-.863 1.8-.37.384-.926.698-1.923 1.026a.75.75 0 000 1.425c.997.327 1.553.641 1.923 1.025.366.38.623.9.863 1.8a.75.75 0 001.45 0c.24-.9.496-1.42.862-1.8.37-.384.926-.698 1.923-1.025a.75.75 0 000-1.425c-.997-.328-1.553-.642-1.923-1.026-.366-.379-.622-.9-.863-1.8a.75.75 0 00-.724-.556z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
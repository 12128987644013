define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar</title><rect x=\".752\" y=\"3.75\" width=\"22.5\" height=\"19.5\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M.752 9.75h22.5M6.752 6V.75M17.252 6V.75\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
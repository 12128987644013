define("ember-one-way-select/helpers/one-way-select/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function contains([haystack, needle, valuePath, targetPath]) {
    if (Ember.isArray(haystack)) {
      haystack = Ember.A(haystack);
      if (valuePath) {
        haystack = targetPath ? haystack : haystack.mapBy(valuePath);
        return Ember.A(haystack).includes(Ember.get(needle, valuePath));
      } else {
        return haystack.includes(needle);
      }
    } else {
      if (valuePath && Ember.isPresent(haystack) && Ember.isPresent(needle)) {
        haystack = targetPath ? haystack : Ember.get(haystack, valuePath);
        return haystack === Ember.get(needle, valuePath);
      } else {
        return haystack === needle;
      }
    }
  }
  var _default = _exports.default = Ember.Helper.helper(contains);
});
define("ember-svg-jar/inlined/posts-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>posts-placeholder</title><path clip-rule=\"evenodd\" d=\"M38.104 2.11a5.5 5.5 0 117.778 7.78L23.35 32.42a1 1 0 01-1.414 0l-6.364-6.364a1 1 0 010-1.414L38.104 2.11zm3.89.39a3.5 3.5 0 00-2.476 1.025L17.693 25.35l4.95 4.95L44.468 8.475A3.5 3.5 0 0041.993 2.5z\"/><path clip-rule=\"evenodd\" d=\"M35.855 4.36a1 1 0 011.414 0l6.364 6.365a1 1 0 01-1.414 1.414l-6.364-6.364a1 1 0 010-1.414zM16.038 24.38a1 1 0 01.95.263l6.363 6.364a1 1 0 01-.416 1.663l-9.15 2.786a1 1 0 01-1.248-1.247l2.786-9.15a1 1 0 01.715-.68zm.713 2.855l-1.754 5.762 5.762-1.754-4.008-4.008zM2.111 38.111A5.5 5.5 0 016 36.5h1.494a1 1 0 110 2H6a3.5 3.5 0 100 7h31.5a1 1 0 110 2H6a5.5 5.5 0 01-3.89-9.39z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 48 48"
    }
  };
});
define("ember-svg-jar/inlined/members-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>members-post</title><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.75 11.75a1 1 0 00-1 1v9a1 1 0 001 1h9a1 1 0 001-1v-9a1 1 0 00-1-1h-9zm-2 1a2 2 0 012-2h9a2 2 0 012 2v9a2 2 0 01-2 2h-9a2 2 0 01-2-2v-9z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.75 14.25a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h6a.5.5 0 010 1h-6a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h2.25a.5.5 0 010 1h-2.25a.5.5 0 01-.5-.5zM6 1.25a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm-3.5 2.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm1.148 4.504a5.75 5.75 0 016.178.953.5.5 0 11-.666.746A4.751 4.751 0 001.25 13.5v1.75H3a.5.5 0 01.498.45l.704 7.05h3.596l.213-2.135a.5.5 0 11.996.1l-.26 2.585a.5.5 0 01-.497.45h-4.5a.5.5 0 01-.498-.45l-.704-7.05H.75a.5.5 0 01-.5-.5V13.5a5.751 5.751 0 013.398-5.246zM16.5 1.25a2.125 2.125 0 100 4.25 2.125 2.125 0 000-4.25zm-3.125 2.125a3.125 3.125 0 116.25 0 3.125 3.125 0 01-6.25 0z\" fill=\"#000\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.66 6.604a5 5 0 016.173 2.146.5.5 0 11-.866.5 4 4 0 00-6.475-.631.5.5 0 11-.754-.658 5 5 0 011.921-1.357z\" fill=\"#000\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
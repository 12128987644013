define("ember-svg-jar/inlined/analytics-tab-clicked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.48 14.45l2.053-1.018a.3.3 0 00.136-.4L10.2 10s2.885-1.482 2.924-1.535A.523.523 0 0013.2 8a.476.476 0 00-.125-.2L4.508 1.625a.455.455 0 00-.524-.074.523.523 0 00-.284.475l.256 10.065c.002.082.023.16.062.23a.465.465 0 00.383.237.474.474 0 00.23-.05l2.848-1.413 1.366 3.127a.465.465 0 00.634.23z\" stroke=\"#7E8B99\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
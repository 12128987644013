define("ember-svg-jar/inlined/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card</title><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.594 3.156a.564.564 0 00-.563.563v9.562c0 .31.252.563.563.563h13.812c.31 0 .563-.252.563-.563V3.72a.564.564 0 00-.563-.563H1.594zM.03 3.72c0-.863.7-1.563 1.563-1.563h13.812c.863 0 1.563.7 1.563 1.563v9.562c0 .863-.7 1.563-1.563 1.563H1.594c-.863 0-1.563-.7-1.563-1.563V3.72z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.375 6.344a2.156 2.156 0 100 4.313 2.156 2.156 0 000-4.313zM3.219 8.5a3.156 3.156 0 116.312 0 3.156 3.156 0 01-6.312 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.68 5.492a3.157 3.157 0 11-1.582 4.884.501.501 0 01.805-.594 2.152 2.152 0 002.408.766 2.158 2.158 0 000-4.096 2.16 2.16 0 00-2.409.766.5.5 0 11-.804-.595c.395-.533.948-.93 1.581-1.13z\"/>",
    "attrs": {
      "viewBox": "0 0 17 17",
      "fill": "currentColor"
    }
  };
});
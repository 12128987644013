define("liquid-wormhole/templates/components/liquid-append", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    
  <div
    {{did-insert this._didInsert @nodes @notify @replaceNodes}}
    {{did-update this._didUpdate @nodes @notify @replaceNodes}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "Qr2N4/XN",
    "block": "{\"symbols\":[\"@replaceNodes\",\"@notify\",\"@nodes\",\"&default\"],\"statements\":[[2,\"\\n\"],[11,\"div\"],[4,[38,0],[[32,0,[\"_didInsert\"]],[32,3],[32,2],[32,1]],null],[4,[38,1],[[32,0,[\"_didUpdate\"]],[32,3],[32,2],[32,1]],null],[12],[2,\"\\n  \"],[18,4,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\"]}",
    "moduleName": "liquid-wormhole/templates/components/liquid-append.hbs"
  });
});
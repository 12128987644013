define("ember-svg-jar/inlined/reload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reload</title><g fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke=\"currentColor\" stroke-width=\"1.5\"><path d=\"M15.667 9.752h6.09V2.444\"/><path d=\"M21.705 9.579C20.622 5.226 16.688 2 12 2 6.477 2 2 6.477 2 12s4.477 10 10 10c3.3 0 6.228-1.6 8.05-4.065\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>star</title><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M12.71 1.436l3.272 6.482 6.296.624a.787.787 0 01.489 1.343l-5.182 5.136 1.921 6.98a.796.796 0 01-1.125.914l-6.383-3.161-6.375 3.157a.794.794 0 01-1.125-.914l1.92-6.98-5.185-5.136a.787.787 0 01.489-1.343l6.296-.624 3.267-6.478a.801.801 0 011.425 0z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
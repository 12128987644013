define("ember-svg-jar/inlined/analytics-tab-clicked-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Clicked</title><path d=\"M11.85 18.063l2.634-1.306a.3.3 0 00.136-.4L12.75 12.5s3.606-1.852 3.655-1.92A.655.655 0 0016.5 10a.594.594 0 00-.157-.249L5.637 2.031a.569.569 0 00-.311-.15.59.59 0 00-.345.057.654.654 0 00-.356.594l.32 12.58c.003.102.03.201.078.288a.569.569 0 00.479.296.592.592 0 00.287-.062l3.561-1.767 1.708 3.91a.58.58 0 00.792.286z\" stroke=\"#15171A\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  };
});
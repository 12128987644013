define("ember-svg-jar/inlined/filter-dropdown-paid-subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.013 3.313V.5M4.2 10.813c0 1.406 1.256 1.874 2.813 1.874 1.556 0 2.812 0 2.812-1.874C9.825 8 4.2 8 4.2 5.186c0-1.875 1.256-1.875 2.813-1.875 1.556 0 2.812.713 2.812 1.876m-2.812 7.5V15.5\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
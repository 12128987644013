define("ember-svg-jar/inlined/brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Color Brush 3 Streamline Icon: https://streamlinehq.com</desc><path d=\"M1.382 22.522a.552.552 0 01.03-1.01c2.629-1.065 1.781-4.062 2.563-5.664a3.931 3.931 0 015.22-1.8c5.869 2.867-1.117 11.661-7.813 8.474zM13.889 14.1l8.778-11.116a1.353 1.353 0 00-2.018-1.8l-9.892 9.866\" fill=\"none\" stroke=\"currentcolor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
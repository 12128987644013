define("liquid-fire/templates/components/liquid-versions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    {{#each this.versions as |version|~}}
    {{#if (lf-or this.renderWhenFalse version.value)~}}
      {{#liquid-child
        version=version
        liquidChildDidRender=(action "childDidRender")
        class=this.class
      ~}}
        {{yield version.value~}}
      {{/liquid-child}}
    {{/if}}
  {{/each}}
  */
  {
    "id": "E8+Ydico",
    "block": "{\"symbols\":[\"version\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"versions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"renderWhenFalse\"]],[32,1,[\"value\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"version\",\"liquidChildDidRender\",\"class\"],[[32,1],[30,[36,0],[[32,0],\"childDidRender\"],null],[32,0,[\"class\"]]]],[[\"default\"],[{\"statements\":[[18,2,[[32,1,[\"value\"]]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"liquid-child\",\"lf-or\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "liquid-fire/templates/components/liquid-versions.hbs"
  });
});
define("ember-svg-jar/inlined/close-orange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.187.854A.5.5 0 109.48.146L5.167 4.46.854.146a.5.5 0 10-.708.708L4.46 5.167.146 9.48a.5.5 0 00.708.707l4.313-4.313 4.313 4.313a.5.5 0 10.707-.707L5.874 5.167 10.187.854z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 11 11",
      "fill": "none"
    }
  };
});
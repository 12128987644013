define("ember-svg-jar/inlined/event-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.46 13.687v6.923M14 17.148h6.923\" stroke=\"#30CF43\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M13.186 19.49l-1.087.543a.578.578 0 01-.456.028.578.578 0 01-.33-.315l-1.696-3.902-3.534 1.764a.563.563 0 01-.76-.233.622.622 0 01-.078-.288l-.318-12.56a.655.655 0 01.353-.594.563.563 0 01.651.092l9.563 7.948a.595.595 0 01.156.248.654.654 0 01-.095.58c-.06.083-5.938 3.043-.228.194\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
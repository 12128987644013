define("ember-svg-jar/inlined/filter-dropdown-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.996 1.7C4.245 1.7 1.2 4.242 1.2 7.38c.018.795.212 1.574.567 2.276a5.105 5.105 0 001.472 1.78 2.044 2.044 0 01-.185 1.519 1.93 1.93 0 01-.499.583c-.2.156-.43.27-.675.332 0 0-.218.292.32.398 2.167.412 3.928-1.42 3.928-1.42v-.036c.614.147 1.244.22 1.876.22 3.751 0 6.796-2.54 6.796-5.68 0-3.139-3.052-5.652-6.804-5.652z\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
define("ember-svg-jar/inlined/event-email-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"#6C747D\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M14.6 8.364v5.818c0 1.715 2.703 1.962 4.067-.356 1.157-1.963.873-4.955-.571-6.923-2.125-2.9-7.039-3.984-10.608-1.588-3.28 2.202-4.448 6.658-2.635 10.258 1.793 3.564 6.003 5.29 9.813 4.002\"/><path stroke=\"#6C747D\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M11.52 15.23c1.701 0 3.08-1.495 3.08-3.34 0-1.846-1.379-3.341-3.08-3.341-1.702 0-3.081 1.495-3.081 3.34 0 1.846 1.38 3.342 3.08 3.342z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
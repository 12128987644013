define("ember-svg-jar/inlined/analytics-tab-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 12.385c.224.115.449.229.675.343 4.158 2.101 3.954 1.692 7.515 1.692 1.486 0 2.242-.967 2.68-2.384v-.01l1.081-3.605v-.007a1.128 1.128 0 00-1.084-1.457H10.09a1.143 1.143 0 01-.897-.439 1.122 1.122 0 01-.198-.976l.5-2.832a.958.958 0 00-.536-1.123.973.973 0 00-1.196.322l-2.77 5.206a1.143 1.143 0 01-.926.477H2m0-1.143v8.003\" stroke=\"#7E8B99\" stroke-width=\"1.24\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
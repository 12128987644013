define("liquid-wormhole/templates/components/liquid-destination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    <div class="liquid-destination {{this.extraClassesString}} {{if this.hasWormholes "has-wormholes"}}">
    {{#each this.stacks as |stack|}}
      <div class="liquid-destination-stack">
        <LiquidVersions
          @value={{stack.lastObject}}
          @notify={{this}}
          @renderWhenFalse={{true}}
          @name="liquid-wormhole"
          @matchContext={{this.matchContext}}
          @stackName={{stack.name}} as |item|
        >
          <LiquidAppend
            @nodes={{item.nodes}}
            @replaceNodes={{item._replaceNodes}}
            @notify={{item.wormhole}}
            @click={{item.wormhole.click}}
          />
        </LiquidVersions>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "P8POU6zX",
    "block": "{\"symbols\":[\"stack\",\"item\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"liquid-destination \",[32,0,[\"extraClassesString\"]],\" \",[30,[36,0],[[32,0,[\"hasWormholes\"]],\"has-wormholes\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"stacks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"liquid-destination-stack\"],[12],[2,\"\\n      \"],[8,\"liquid-versions\",[],[[\"@value\",\"@notify\",\"@renderWhenFalse\",\"@name\",\"@matchContext\",\"@stackName\"],[[32,1,[\"lastObject\"]],[32,0],true,\"liquid-wormhole\",[32,0,[\"matchContext\"]],[32,1,[\"name\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"liquid-append\",[],[[\"@nodes\",\"@replaceNodes\",\"@notify\",\"@click\"],[[32,2,[\"nodes\"]],[32,2,[\"_replaceNodes\"]],[32,2,[\"wormhole\"]],[32,2,[\"wormhole\",\"click\"]]]],null],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "liquid-wormhole/templates/components/liquid-destination.hbs"
  });
});
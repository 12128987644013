define("ember-svg-jar/inlined/portal-icon-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>portal-icon-3</title><path d=\"M23.5 6v14.25a2.25 2.25 0 11-4.5 0V3c0-.398-.158-.78-.44-1.06a1.494 1.494 0 00-1.06-.44h-15c-.398 0-.78.158-1.06.44C1.157 2.22 1 2.601 1 3v17.25a2.25 2.25 0 002.25 2.25h18M4.75 15h10.5m-10.5 3h6\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M14.5 5.25h-9a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h9a.75.75 0 00.75-.75V6a.75.75 0 00-.75-.75z\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 25 24",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/koenig/kg-trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M1.917 5.583h20.166m-8.02-3.666H9.936a1.375 1.375 0 00-1.374 1.375v2.291h6.874V3.292a1.375 1.375 0 00-1.374-1.375zM9.938 17.27v-6.874m4.125 6.874v-6.874\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M18.288 20.818a1.366 1.366 0 01-1.366 1.265H7.077a1.366 1.366 0 01-1.365-1.265L4.438 5.583h15.125l-1.275 15.235z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
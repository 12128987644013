define("liquid-wormhole/services/liquid-wormhole", ["exports", "jquery", "perf-primitives/hash-map", "liquid-wormhole/components/liquid-destination"], function (_exports, _jquery, _hashMap, _liquidDestination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let LiquidWormholeService = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class LiquidWormholeService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.destination = new _hashMap.default();
      Ember.getOwner(this).register('component:-liquid-destination', _liquidDestination.default);
    }
    willDestroy() {
      this.removeDefaultDestination();
    }
    appendWormhole(wormhole, destinationName = 'default') {
      let destination = this.destination.get(destinationName);
      if (destination === undefined) {
        if (destinationName === 'default') {
          destination = this.addDefaultDestination();
        } else {
          throw new Error('Liquid Wormhole destination does not exist');
        }
      }
      destination.appendWormhole(wormhole);
    }
    removeWormhole(wormhole, destinationName = 'default') {
      const destination = this.destination.get(destinationName);
      if (destination === undefined) {
        throw new Error('Liquid Wormhole destination does not exist');
      }
      destination.removeWormhole(wormhole);
    }
    registerDestination(destinationName, destination) {
      if (this.destination.get(destinationName)) {
        throw new Error(`Liquid Wormhole destination '${destinationName}' already created`);
      }
      this.destination.set(destinationName, destination);
    }
    unregisterDestination(destinationName) {
      this.destination.delete(destinationName);
    }
    addDefaultDestination() {
      const instance = Ember.getOwner(this);
      const destination = instance.lookup('component:-liquid-destination');
      destination.set('extraClassesString', 'default-liquid-destination');
      if (instance.rootElement) {
        destination.appendTo(instance.rootElement);
      } else if ((0, _jquery.default)('.ember-application').length > 0) {
        destination.appendTo((0, _jquery.default)('.ember-application')[0]);
      } else {
        destination.appendTo(document);
      }
      this.defaultDestination = destination;
      return destination;
    }
    removeDefaultDestination() {
      if (this.defaultDestination) {
        this.defaultDestination.destroy();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "appendWormhole", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "appendWormhole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeWormhole", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "removeWormhole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerDestination", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "registerDestination"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unregisterDestination", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "unregisterDestination"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addDefaultDestination", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addDefaultDestination"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeDefaultDestination", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeDefaultDestination"), _class.prototype)), _class));
});
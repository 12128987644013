define("ember-svg-jar/inlined/globe-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#globe-simple_svg__clip0_1558_11351)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.007 1.913c-.233.762-.714 2.428-.983 4.07.697.112 1.378.184 1.976.184.597 0 1.278-.072 1.975-.185-.269-1.641-.75-3.307-.983-4.07a6.215 6.215 0 00-1.985 0zm3.116.296c.257.89.613 2.242.836 3.592a34.5 34.5 0 002.522-.629 6.19 6.19 0 00-3.358-2.963zM13.87 6.1a35.54 35.54 0 01-2.774.692c.045.415.071.822.071 1.207 0 .562-.056 1.173-.144 1.788 1.283-.268 2.46-.61 3.065-.796a6.211 6.211 0 00-.218-2.89zm-.078 4.023c-.748.216-1.823.502-2.948.72a34.624 34.624 0 01-.72 2.947 6.186 6.186 0 003.668-3.667zm-4.8 3.963c.187-.604.529-1.781.796-3.064A12.85 12.85 0 018 11.167a12.85 12.85 0 01-1.788-.144c.268 1.283.61 2.46.795 3.065a6.211 6.211 0 001.985 0zm-3.115-.296a34.644 34.644 0 01-.72-2.947 34.635 34.635 0 01-2.948-.72 6.186 6.186 0 003.668 3.667zM1.912 8.992a35.71 35.71 0 003.065.796A12.847 12.847 0 014.833 8c0-.385.026-.792.071-1.207a35.546 35.546 0 01-2.773-.692 6.162 6.162 0 00-.219 2.891zm.606-3.82c.658.19 1.563.431 2.523.629.222-1.35.578-2.703.835-3.592a6.189 6.189 0 00-3.358 2.963zm3.373 1.802c-.037.36-.058.706-.058 1.026 0 .599.072 1.282.185 1.981.7.114 1.383.186 1.982.186.598 0 1.281-.072 1.981-.186.113-.7.185-1.382.185-1.98 0-.321-.02-.667-.057-1.027-.722.115-1.45.193-2.11.193-.659 0-1.387-.078-2.108-.193zM6.517.987a7.194 7.194 0 012.965 0 7.179 7.179 0 015.156 4.307 7.19 7.19 0 01.375 4.189A7.175 7.175 0 018 15.167 7.175 7.175 0 01.833 8c0-.957.188-1.872.53-2.708A7.179 7.179 0 016.517.987z\"/></g><defs><clipPath id=\"globe-simple_svg__clip0_1558_11351\"><path d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "currentColor"
    }
  };
});
define("ember-svg-jar/inlined/email-unread", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-unread</title><path d=\"M2.667 3.136a.833.833 0 00-.833.833v8c0 .46.373.833.833.833h12.667c.46 0 .833-.373.833-.833v-8a.833.833 0 00-.833-.833H2.667zm-1.833.833c0-1.012.82-1.833 1.833-1.833h12.667c1.012 0 1.833.82 1.833 1.833v8c0 1.013-.82 1.833-1.833 1.833H2.667A1.833 1.833 0 01.834 11.97v-8z\"/><path d=\"M1.34 2.708a.5.5 0 01.703-.071l6.958 5.687 6.957-5.687a.5.5 0 11.633.774L9.317 9.356a.5.5 0 01-.633 0L1.41 3.411a.5.5 0 01-.07-.703z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 18 16"
    }
  };
});
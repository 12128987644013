define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 .333A3.167 3.167 0 002.833 3.5v.167H.667a.5.5 0 000 1h10.666a.5.5 0 000-1H9.166V3.5A3.167 3.167 0 006 .333zm0 1c1.196 0 2.166.97 2.166 2.167v.167H3.833V3.5c0-1.196.97-2.167 2.167-2.167z\" fill=\"#fff\"/><path d=\"M1.83 5.453a.5.5 0 00-.995.094c.063.663.177 1.48.323 2.53l.188 1.349c.18 1.29.281 2.02.501 2.62.41 1.115 1.14 2.01 2.08 2.406.511.215 1.095.215 1.964.215h.217c.87 0 1.453 0 1.964-.215.94-.397 1.67-1.29 2.08-2.406.22-.6.322-1.33.502-2.62l.188-1.35c.146-1.049.26-1.866.322-2.529a.5.5 0 00-.995-.094c-.06.639-.172 1.434-.32 2.498l-.174 1.257c-.194 1.391-.283 2.008-.461 2.493-.344.936-.916 1.57-1.531 1.83-.3.126-.664.136-1.683.136-1.02 0-1.383-.01-1.683-.136-.615-.26-1.187-.894-1.531-1.83-.178-.485-.267-1.102-.46-2.493L2.15 7.95c-.148-1.064-.258-1.86-.32-2.498z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 12 15",
      "fill": "none"
    }
  };
});
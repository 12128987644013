define("ember-svg-jar/inlined/event-subscribed-to-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.54 13.764v6.923m-3.463-3.462H20m-9.808-1.154H6.154A1.154 1.154 0 015 14.917V6.84a1.154 1.154 0 011.154-1.153h11.538a1.154 1.154 0 011.154 1.153v5.193\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M5 7.14l6.923 4.893 6.923-4.892\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16.54 13.764v6.923m-3.463-3.462H20\" stroke=\"#30CF43\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
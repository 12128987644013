define("liquid-wormhole/services/liquid-fire-transitions", ["exports", "liquid-fire/action", "liquid-fire/running-transition", "liquid-fire/transition-map"], function (_exports, _action, _runningTransition, _transitionMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const wormholeActionMap = new WeakMap();
  class LiquidFireTransitionsService extends _transitionMap.default {
    transitionFor(conditions) {
      if (conditions.matchContext && conditions.matchContext.helperName === 'liquid-wormhole' || conditions.helperName === 'liquid-wormhole') {
        const versions = conditions.versions;
        conditions.versions = versions.map(version => version.value || version);
        conditions.parentElement = conditions.parentElement.find('.liquid-wormhole-element');
        conditions.firstTime = 'no';
        const rule = this.constraintsFor(conditions).bestMatch(conditions);
        let action;
        if (rule) {
          if (wormholeActionMap.has(rule)) {
            action = wormholeActionMap.get(rule);
          } else {
            action = new _action.default('wormhole', [{
              use: rule.use
            }]);
            action.validateHandler(this);
            wormholeActionMap.set(rule, action);
          }
        } else {
          action = this.defaultAction();
        }
        return new _runningTransition.default(this, versions, action);
      } else {
        return super.transitionFor(conditions);
      }
    }
  }
  _exports.default = LiquidFireTransitionsService;
});
define("ember-svg-jar/inlined/send-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>send-email</title><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M2.05 10.387a1.487 1.487 0 01-.069-2.84L22.052 1a.751.751 0 01.948.943l-6.541 20.081a1.486 1.486 0 01-2.841-.07l-2.246-9.33-9.322-2.237z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/event-more-like-this", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6 15.761c.243.125.487.249.731.372 4.505 2.275 4.284 1.832 8.141 1.832 1.61 0 2.43-1.047 2.905-2.581v-.01l1.17-3.905v-.008a1.222 1.222 0 00-1.174-1.577h-3.01a1.24 1.24 0 01-.972-.475 1.215 1.215 0 01-.214-1.058l.541-2.041a1.038 1.038 0 00-.58-1.216 1.054 1.054 0 00-1.295.35l-3.001 4.611a1.238 1.238 0 01-1.003.516H6M6 9.333V18\" stroke=\"#6D747C\" stroke-width=\"1.55\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/filter-dropdown-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.51 11.956l.72.366c4.435 2.24 4.218 1.804 8.016 1.804 1.585 0 2.391-1.031 2.86-2.542v-.01l1.152-3.844v-.008a1.203 1.203 0 00-1.156-1.553h-2.963a1.22 1.22 0 01-.958-.468 1.196 1.196 0 01-.21-1.04l.532-2.01a1.022 1.022 0 00-.57-1.198 1.038 1.038 0 00-1.276.344l-2.955 4.54a1.219 1.219 0 01-.988.509H1.511M1.51 5.627v8.533\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
define("ember-svg-jar/inlined/paint-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paint-palette</title><defs><style>.paint-palette_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><circle class=\"paint-palette_svg__a\" cx=\"5.288\" cy=\"5.25\" r=\"1.5\"/><circle class=\"paint-palette_svg__a\" cx=\"5.288\" cy=\"11.25\" r=\"1.5\"/><circle class=\"paint-palette_svg__a\" cx=\"5.288\" cy=\"17.25\" r=\"1.5\"/><path class=\"paint-palette_svg__a\" d=\"M9.2 19.337a3.913 3.913 0 01-7.826 0V2.707A1.957 1.957 0 013.331.75h3.913A1.957 1.957 0 019.2 2.707z\"/><path class=\"paint-palette_svg__a\" d=\"M8.643 21.352l8.349-14.461a1.957 1.957 0 00-.716-2.673l-3.389-1.956A1.951 1.951 0 0011.87 2\"/><path class=\"paint-palette_svg__a\" d=\"M7.293 22.7l14.354-8.29a1.957 1.957 0 00.716-2.672l-1.957-3.389a1.945 1.945 0 00-.5-.57\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
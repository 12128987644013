define("ember-svg-jar/inlined/social-linkedin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#social-linkedin_svg__clip0_537_833)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.778 16h12.444c.982 0 1.778-.796 1.778-1.778V1.778C16 .796 15.204 0 14.222 0H1.778C.796 0 0 .796 0 1.778v12.444C0 15.204.796 16 1.778 16z\" fill=\"#007ebb\" class=\"social-linkedin_svg__linkedin\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.778 13.778h-2.374V9.734c0-1.109-.421-1.729-1.299-1.729-.955 0-1.453.645-1.453 1.729v4.044H6.363V6.074h2.289v1.038s.688-1.273 2.322-1.273c1.634 0 2.804.997 2.804 3.061v4.878zM3.634 5.065c-.78 0-1.411-.636-1.411-1.421s.631-1.422 1.41-1.422c.78 0 1.411.637 1.411 1.422 0 .785-.631 1.421-1.41 1.421zm-1.182 8.713h2.386V6.074H2.452v7.704z\" fill=\"#fff\"/></g><defs><clipPath id=\"social-linkedin_svg__clip0_537_833\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/arrow2-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow2-down</title><path d=\"M23.831 10.623a.497.497 0 00-.705.045L13.001 22.173V.498a.5.5 0 00-1 0v21.675L1.876 10.668a.499.499 0 10-.75.66l11 12.5.011.008.123.091.04.027.201.043.201-.043.04-.027.123-.091.011-.008 11-12.5a.498.498 0 00-.045-.705z\"/>",
    "attrs": {
      "version": "1",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.703.998a.375.375 0 00-.75.004l.004.993c.002.384.003.648-.016.85A5.375 5.375 0 00.625 6.5a.375.375 0 10.75 0 4.625 4.625 0 018.082-3.073 1.701 1.701 0 01-.08.01 8.77 8.77 0 01-.883.02l-.993-.004a.375.375 0 00-.003.75l1.014.005c.378.002.693.003.946-.025.265-.029.51-.093.722-.258.092-.07.174-.153.245-.244.164-.213.229-.457.258-.723.028-.253.026-.568.024-.945L10.704.998zM11.375 6.5a.375.375 0 00-.75 0 4.625 4.625 0 01-8.118 3.032l.076-.01c.205-.023.477-.022.882-.02l.993.004a.375.375 0 10.003-.75l-1.014-.004c-.378-.002-.693-.003-.946.024-.265.03-.51.094-.722.259-.092.07-.174.152-.245.244-.164.212-.23.457-.258.723a9.075 9.075 0 00-.025.945l.005 1.014a.375.375 0 10.75-.003L2 10.965a9.509 9.509 0 01.018-.854A5.375 5.375 0 0011.375 6.5z\" fill=\"#EF5A2B\"/>",
    "attrs": {
      "viewBox": "0 0 12 13",
      "fill": "currentColor"
    }
  };
});
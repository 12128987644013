define("ember-svg-jar/inlined/audio-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file</title><path clip-rule=\"evenodd\" d=\"M7.5 15.33a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM15 13.83a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z\"/><path clip-rule=\"evenodd\" d=\"M14.486 6.81A2.25 2.25 0 0117.25 9v5.579a.75.75 0 01-1.5 0v-5.58a.75.75 0 00-.932-.727.755.755 0 01-.059.013l-4.465.744a.75.75 0 00-.544.72v6.33a.75.75 0 01-1.5 0v-6.33a2.25 2.25 0 011.763-2.194l4.473-.746z\"/><path clip-rule=\"evenodd\" d=\"M3 1.5a.75.75 0 00-.75.75v19.5a.75.75 0 00.75.75h18a.75.75 0 00.75-.75V5.133a.75.75 0 00-.225-.535l-.002-.002-3-2.883A.75.75 0 0018 1.5H3zM1.409.659A2.25 2.25 0 013 0h15a2.25 2.25 0 011.568.637l.003.002 3 2.883a2.25 2.25 0 01.679 1.61V21.75A2.25 2.25 0 0121 24H3a2.25 2.25 0 01-2.25-2.25V2.25c0-.597.237-1.169.659-1.591z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
define("liquid-fire/templates/components/liquid-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    {{yield this}}
  */
  {
    "id": "CXFd+X02",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "liquid-fire/templates/components/liquid-container.hbs"
  });
});
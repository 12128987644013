define("ember-svg-jar/inlined/place-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M102.883 34.88l-5.5 39.324c-1.192 8.25-5.73 12.604-13.2 12.604-.596 0-1.237-.046-1.879-.091.138-.871.183-1.741.183-2.704V44.275c0-8.112-4.033-12.191-12.191-12.191H24.6c0-.092.045-.23.045-.322l1.1-7.7c.642-4.674 2.43-8.112 5.18-10.22 2.796-2.062 6.554-2.796 11.23-2.154l48.399 6.783c4.629.688 8.067 2.429 10.175 5.225 2.109 2.75 2.842 6.555 2.154 11.184z\" fill=\"#EF5A2B\"/><path opacity=\".4\" d=\"M70.285 32.075H21.39c-8.15 0-12.224 4.074-12.224 12.223v39.728c0 8.15 4.075 12.224 12.224 12.224h48.895c8.149 0 12.223-4.075 12.223-12.224V44.298c.005-8.154-4.07-12.224-12.223-12.224z\" fill=\"#EF5A2B\"/><path d=\"M75.221 68.622L60.958 54.313c-1.604-1.558-4.217-1.558-5.775 0l-20.121 20.12a2.05 2.05 0 01-2.888 0L28.37 70.63c-1.604-1.604-4.216-1.604-5.775 0l-6.15 6.151c-.257.257-.404.61-.404.973v6.26c0 4.355 1.009 5.363 5.363 5.363h48.904c4.308 0 5.316-1.008 5.316-5.363V69.594c0-.366-.142-.715-.403-.972zM29.801 57.292c-2.525 0-4.592-2.054-4.592-4.584s2.03-4.583 4.556-4.583h.036a4.58 4.58 0 014.575 4.583 4.58 4.58 0 01-4.575 4.584z\" fill=\"#EF5A2B\"/>",
    "attrs": {
      "style": "width:110px;height:110px",
      "viewBox": "0 0 110 110",
      "fill": "none"
    }
  };
});
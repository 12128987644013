define("liquid-fire/templates/components/liquid-spacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    <LiquidMeasured @didMeasure={{action this.sizeChanged}}>
    {{yield}}
  </LiquidMeasured>
  */
  {
    "id": "4r1Z9TMa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"liquid-measured\",[],[[\"@didMeasure\"],[[30,[36,0],[[32,0],[32,0,[\"sizeChanged\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "liquid-fire/templates/components/liquid-spacer.hbs"
  });
});
define("ember-svg-jar/inlined/stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.75 5a.75.75 0 00-1.5 0v10a.75.75 0 001.5 0V5zM14.75 9a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0V9zM6.75 11a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-4z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.463.374C13.322.25 11.883.25 10.046.25h-.09c-1.837 0-3.276 0-4.419.124-1.165.126-2.11.388-2.916.974A5.75 5.75 0 001.348 2.62C.762 3.427.5 4.37.374 5.536.25 6.68.25 8.118.25 9.955v.09c0 1.837 0 3.276.124 4.418.126 1.166.388 2.11.974 2.917a5.75 5.75 0 001.272 1.272c.807.586 1.75.848 2.916.974 1.143.124 2.582.124 4.419.124h.09c1.837 0 3.276 0 4.418-.124 1.166-.126 2.11-.388 2.917-.974a5.749 5.749 0 001.272-1.272c.586-.807.848-1.75.974-2.916.124-1.143.124-2.582.124-4.419v-.09c0-1.837 0-3.276-.124-4.419-.126-1.165-.388-2.11-.974-2.916a5.75 5.75 0 00-1.272-1.272C16.573.762 15.63.5 14.464.374zM3.502 2.562c.508-.37 1.158-.585 2.196-.697C6.75 1.751 8.108 1.75 10 1.75c1.892 0 3.25.001 4.302.115 1.038.112 1.688.328 2.196.697.36.262.678.58.94.94.37.508.585 1.158.697 2.196.114 1.052.115 2.41.115 4.302 0 1.892-.001 3.25-.115 4.302-.113 1.038-.328 1.688-.697 2.196-.262.36-.58.678-.94.94-.508.37-1.158.585-2.196.697-1.052.114-2.41.115-4.302.115-1.892 0-3.25-.001-4.302-.115-1.038-.113-1.688-.328-2.196-.697a4.25 4.25 0 01-.94-.94c-.37-.508-.585-1.158-.697-2.196-.114-1.052-.115-2.41-.115-4.302 0-1.892.001-3.25.115-4.302.112-1.038.328-1.688.697-2.196.262-.36.58-.678.94-.94z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  };
});
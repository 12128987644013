define("ember-svg-jar/inlined/compass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass</title><defs><style>.compass_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"compass_svg__a\" d=\"M9.58 14.42l1.853 3.336a.75.75 0 001.36-.108l3.132-8.612a.75.75 0 00-.961-.961l-8.612 3.132a.75.75 0 00-.108 1.36z\"/><circle class=\"compass_svg__a\" cx=\"12\" cy=\"12\" r=\"11.25\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/empty-conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#empty-conversion_svg__clip0_127_11402)\" stroke=\"#CFD4D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M20.508 22.306c6.09 0 11.029-4.77 11.029-10.653C31.537 5.769 26.599 1 20.507 1 14.418 1 9.48 5.77 9.48 11.653c0 5.883 4.937 10.653 11.029 10.653zM4 39.334v-1.09c0-4.227 1.738-8.28 4.833-11.27 3.094-2.988 7.29-4.667 11.667-4.667 4.376 0 8.573 1.679 11.667 4.668C35.262 29.962 37 34.017 37 38.243v1.09\"/></g><defs><clipPath id=\"empty-conversion_svg__clip0_127_11402\"><path fill=\"#fff\" d=\"M0 0h40v40H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/twitter-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>twitter-link</title><path d=\"M11.96 14.945a.833.833 0 01-.203-.027 5.192 5.192 0 01-2.795-1.932c-.832-1.125-1.176-2.508-.968-3.893s.942-2.605 2.068-3.438l3.53-2.608a5.25 5.25 0 017.33 1.1c.83 1.127 1.175 2.51.967 3.895s-.943 2.605-2.07 3.438l-1.48 1.094a.752.752 0 01-.892-1.208l1.48-1.095a3.713 3.713 0 001.476-2.45 3.724 3.724 0 00-.69-2.778 3.745 3.745 0 00-5.23-.784l-3.53 2.608a3.72 3.72 0 00-1.475 2.45c-.15.99.097 1.975.69 2.778a3.701 3.701 0 001.992 1.377.752.752 0 01-.202 1.475z\" fill=\"#5b7083\"/><path d=\"M7.27 22.054a5.24 5.24 0 01-5.193-6.019 5.21 5.21 0 012.07-3.438l1.478-1.094a.752.752 0 01.893 1.208l-1.48 1.095a3.716 3.716 0 00-1.475 2.45c-.148.99.097 1.975.69 2.778a3.745 3.745 0 005.23.785l3.528-2.608a3.744 3.744 0 00.785-5.23 3.7 3.7 0 00-1.992-1.376.75.75 0 01-.52-.927c.112-.4.528-.63.926-.522a5.19 5.19 0 012.794 1.932 5.248 5.248 0 01-1.1 7.33l-3.53 2.608a5.189 5.189 0 01-3.105 1.026z\" fill=\"#5b7083\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/dotdotdot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>circle{fill:currentColor}</style></defs><circle cx=\"3.25\" cy=\"12\" r=\"2.6\"/><circle cx=\"12\" cy=\"12\" r=\"2.6\"/><circle cx=\"20.75\" cy=\"12\" r=\"2.6\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/portal-icon-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>portal-icon-4</title><path d=\"M21.75 1.5H2.25A1.5 1.5 0 00.75 3v12a1.5 1.5 0 001.5 1.5h19.5a1.5 1.5 0 001.5-1.5V3a1.5 1.5 0 00-1.5-1.5zm-6.063 5.475L19.5 10.5M8.313 6.975L4.5 10.5\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M22.88 2.014l-9.513 6.56a2.41 2.41 0 01-2.734 0L1.12 2.014\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 18",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/event-email-delivery-spam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"event-email-delivery-spam_svg__body\" d=\"M14.412 18.978a1.22 1.22 0 01-1.163-.332l-2.414-2.404-2.592 1.34.1-3.843-3.036-3.035a1.219 1.219 0 01-.332-1.108 1.24 1.24 0 01.82-.953L17.68 4.678a1.24 1.24 0 011.595 1.595l-1.307 3.919m.949-5.266L8.339 13.743\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M17.634 13.35v2.869\" stroke=\"#F50B23\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"17.639\" cy=\"18.75\" r=\".75\" fill=\"#F50B23\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
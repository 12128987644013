define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>help</title><g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#343F44\"><path d=\"M6.875 8.118c-.003-1.94.963-2.98 2.897-3.122 1.934-.143 3.042.745 3.323 2.664.282 1.92-.525 3.088-2.418 3.507-.446.088-.671.358-.677.812v.928m0 2.086c.139 0 .208.07.208.209 0 .139-.07.208-.208.208-.139 0-.208-.07-.208-.208a.204.204 0 01.208-.208\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M19.5 10c0-2.629-.924-4.859-2.782-6.718C14.858 1.424 12.628.5 10 .5c-2.629 0-4.859.924-6.718 2.782C1.424 5.142.5 7.372.5 10c0 2.629.924 4.859 2.782 6.718C5.142 18.576 7.372 19.5 10 19.5c2.629 0 4.859-.924 6.718-2.782 1.858-1.86 2.782-4.09 2.782-6.718z\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20"
    }
  };
});
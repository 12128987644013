define("liquid-wormhole/components/liquid-destination", ["exports", "@ember-decorators/component", "perf-primitives/hash-map", "liquid-wormhole/templates/components/liquid-destination"], function (_exports, _component, _hashMap, _liquidDestination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2;
  /* eslint-disable ember/no-computed-properties-in-native-classes */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LiquidDestination = _exports.default = (_dec = (0, _component.layout)(_liquidDestination.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.inject.service('liquidWormhole'), _dec4 = Ember.computed.gt('stacks.length', 0), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class LiquidDestination extends Ember.Component {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "liquidWormholeService", _descriptor, this);
      _defineProperty(this, "extraClassesString", '');
      _defineProperty(this, "name", 'default');
      _initializerDefineProperty(this, "hasWormholes", _descriptor2, this);
      this.stackMap = new _hashMap.default();
      Ember.set(this, 'matchContext', {
        helperName: 'liquid-wormhole'
      });
      Ember.set(this, 'stacks', Ember.A());
      this.wormholeQueue = Ember.A();
      const name = this.name;
      this.liquidWormholeService.registerDestination(name, this);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      const name = this.name;
      this.liquidWormholeService.unregisterDestination(name);
    }
    appendWormhole(wormhole) {
      // The order that wormholes are rendered in may be different from the order
      // that they appear in templates, because child components get rendered before
      // their parents. This logic inserts parent components *before* their children
      // so the ordering is correct.
      var appendIndex = this.wormholeQueue.get('length') - 1;
      for (; appendIndex >= 0; appendIndex--) {
        const lastWormholeElement = this.wormholeQueue.objectAt(appendIndex).element;
        if (!wormhole.element.contains(lastWormholeElement)) {
          break; // break when we find the first wormhole that isn't a parent
        }
      }
      this.wormholeQueue.insertAt(appendIndex + 1, wormhole);
      Ember.run.scheduleOnce('afterRender', this, this.flushWormholeQueue);
    }
    removeWormhole(wormhole) {
      const stackName = wormhole.get('stack');
      const stack = this.stackMap.get(stackName);
      const item = stack.find(item => item && item.wormhole === wormhole);
      const newNodes = item.get('nodes').clone();
      item.set('nodes', newNodes);
      item.set('_replaceNodes', true);
      Ember.run.next(() => stack.removeObject(item));
    }
    flushWormholeQueue() {
      this.wormholeQueue.forEach(wormhole => {
        const stackName = wormhole.get('stack');
        const stack = this.stackMap.get(stackName) || this.createStack(wormhole);
        const nodes = wormhole.get('nodes');
        const value = wormhole.get('value');
        const item = Ember.Object.create({
          nodes,
          wormhole,
          value
        });

        // Reset visibility in case we made them visible, see below
        for (const node of nodes) {
          node.style.visibility = 'hidden';
        }
        stack.pushObject(item);
      });
      this.wormholeQueue.clear();
    }
    createStack(wormhole) {
      const stackName = wormhole.get('stack');
      const stack = Ember.A([null]);
      stack.set('name', stackName);
      this.stackMap.set(stackName, stack);
      this.stacks.pushObject(stack);
      return stack;
    }
    willTransition() {
      // Do nothing
    }
    afterChildInsertion() {
      // Do nothing
    }
    afterTransition([{
      value,
      view
    }]) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      // If wormholes were made w/o animations, they need to be made visible manually.
      const liquidWormholeElement = view.element.querySelector('.liquid-wormhole-element');
      if (liquidWormholeElement) {
        liquidWormholeElement.style.visibility = 'visible';
      }

      // Clean empty stacks
      if (value === null) {
        const stacks = this.stacks;
        const stackName = view.get('parentView.stackName');
        const stack = this.stackMap.get(stackName);
        stacks.removeObject(stack);
        this.stackMap.delete(stackName);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "liquidWormholeService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "hasWormholes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "willTransition", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "willTransition"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "afterChildInsertion", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "afterChildInsertion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "afterTransition", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "afterTransition"), _class2.prototype)), _class2)) || _class) || _class);
});
define("ember-svg-jar/inlined/newsletter-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.063 11.753l.712.362c4.395 2.221 4.18 1.789 7.943 1.789 1.57 0 2.37-1.023 2.833-2.52v-.01l1.142-3.81v-.008a1.192 1.192 0 00-1.145-1.54H10.61a1.208 1.208 0 01-.948-.463 1.185 1.185 0 01-.21-1.032l.529-1.993a1.013 1.013 0 00-.566-1.186 1.028 1.028 0 00-1.264.34L5.226 6.185a1.208 1.208 0 01-.979.503H2.062M.855 5.48h1.209v8.459H.854\" stroke=\"currentColor\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
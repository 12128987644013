define("ember-svg-jar/inlined/empty-clicked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#empty-clicked_svg__clip0_127_11449)\" stroke=\"#CFD4D9\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M30.426 24.26v15.7M22.576 32.109h15.7M20.73 37.42l-2.466 1.231a1.31 1.31 0 01-1.033.064 1.31 1.31 0 01-.75-.713l-3.844-8.85-8.016 4c-.203.101-.425.15-.647.141a1.277 1.277 0 01-.624-.191 1.316 1.316 0 01-.453-.479 1.411 1.411 0 01-.176-.652l-.72-28.483a1.486 1.486 0 01.208-.793 1.44 1.44 0 01.592-.553c.243-.122.513-.167.776-.13.263.038.506.156.7.34l21.686 18.021c.162.153.283.347.354.564a1.485 1.485 0 01-.214 1.315c-.137.187-13.466 6.9-.518.44\"/></g><defs><clipPath id=\"empty-clicked_svg__clip0_127_11449\"><path fill=\"#fff\" d=\"M0 0h40v40H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "fill": "none"
    }
  };
});
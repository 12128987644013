define("ember-svg-jar/inlined/email-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-at</title><path d=\"M11.126 14.3A7.032 7.032 0 1115.032 8M8.001 11.124a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z\" stroke=\"currentColor\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M11.126 8v1.17a1.953 1.953 0 003.906 0V8\" stroke=\"currentColor\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
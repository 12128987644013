define("ember-svg-jar/inlined/circle-ellipsis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circle-ellipsis</title><defs><style>.circle-ellipsis_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><circle class=\"circle-ellipsis_svg__a\" cx=\"3.375\" cy=\"12.023\" r=\"2.625\"/><circle class=\"circle-ellipsis_svg__a\" cx=\"20.625\" cy=\"12.023\" r=\"2.625\"/><circle class=\"circle-ellipsis_svg__a\" cx=\"12\" cy=\"12.023\" r=\"2.625\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M21 5H3a2 2 0 00-2 2v11a2 2 0 002 2h18a2 2 0 002-2V7a2 2 0 00-2-2zM5 10h2m4 0h2m4 0h2M5 15h2m4 0h8\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
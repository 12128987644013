define("ember-svg-jar/inlined/analytics-tab-sent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.817 11.53l1.977 1.968a.997.997 0 00.952.272 1.016 1.016 0 00.734-.671l3.246-9.73a1.016 1.016 0 00-1.305-1.305L2.69 5.31a1.016 1.016 0 00-.67.78.997.997 0 00.272.906l2.484 2.485-.082 3.146 2.122-1.097zM13.433 2.266l-8.66 7.217\" stroke=\"#7E8B99\" stroke-width=\"1.2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/arrow-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-up</title><path fill=\"#010101\" d=\"M47.753 36.485l-23-26.285c-.381-.436-1.125-.436-1.506 0l-23 26.285a1 1 0 001.506 1.316L24 12.376l22.247 25.425a.996.996 0 001.411.095 1 1 0 00.095-1.411z\"/>",
    "attrs": {
      "viewBox": "0 0 48 48"
    }
  };
});
define("ember-svg-jar/inlined/event-canceled-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.438 6.549v-3.42m2.084 3.844c-.576-.275-1.299-.424-2.083-.424-1.893 0-3.42 0-3.42 2.28 0 1.027.617 1.746 1.481 2.342m4.022 2.259c.787.575 1.337 1.27 1.337 2.239 0 2.28-1.528 2.28-3.42 2.28-.72 0-1.389-.083-1.939-.28m1.938.281v3.42\" stroke=\"#6C747D\" stroke-width=\"1.714\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M15.938 7.556L7.502 16.76\" stroke=\"#F50B23\" stroke-width=\"1.7\" stroke-linecap=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
define("liquid-fire/templates/components/liquid-bind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    {{#if this.containerless}}
    {{~#liquid-versions
      value=this.value
      use=this.use
      rules=this.rules
      matchContext=this.forwardMatchContext
      versionEquality=this.versionEquality
      renderWhenFalse=true
      class=this.class as |version|
    ~}}
      {{~#if (has-block)}}
        {{~yield version~}}
      {{else}}
        {{~version~}}
      {{/if~}}
    {{/liquid-versions~}}
  {{else}}
    {{~#liquid-container
      id=this.containerId
      class=this.class
      growDuration=this.growDuration
      growPixelsPerSecond=this.growPixelsPerSecond
      growEasing=this.growEasing
      shrinkDelay=this.shrinkDelay
      growDelay=this.growDelay
      enableGrowth=this.enableGrowth as |container|
    ~}}
      {{~#liquid-versions
        value=this.value
        notify=container
        use=this.use
        rules=this.rules
        matchContext=this.forwardMatchContext
        versionEquality=this.versionEquality
        renderWhenFalse=true as |version| 
      ~}}
        {{~#if (has-block)}}
          {{~yield version~}}
        {{else}}
          {{~version~}}
        {{/if~}}
      {{/liquid-versions~}}
    {{/liquid-container~}}
  {{/if}}
  */
  {
    "id": "dS0w+JQh",
    "block": "{\"symbols\":[\"container\",\"version\",\"version\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"containerless\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"value\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\",\"class\"],[[32,0,[\"value\"]],[32,0,[\"use\"]],[32,0,[\"rules\"]],[32,0,[\"forwardMatchContext\"]],[32,0,[\"versionEquality\"]],true,[32,0,[\"class\"]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[18,4,[[32,3]]]],\"parameters\":[]},{\"statements\":[[1,[32,3]]],\"parameters\":[]}]]]],\"parameters\":[3]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[32,0,[\"containerId\"]],[32,0,[\"class\"]],[32,0,[\"growDuration\"]],[32,0,[\"growPixelsPerSecond\"]],[32,0,[\"growEasing\"]],[32,0,[\"shrinkDelay\"]],[32,0,[\"growDelay\"]],[32,0,[\"enableGrowth\"]]]],[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"value\",\"notify\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\"],[[32,0,[\"value\"]],[32,1],[32,0,[\"use\"]],[32,0,[\"rules\"]],[32,0,[\"forwardMatchContext\"]],[32,0,[\"versionEquality\"]],true]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,4]]],null,[[\"default\",\"else\"],[{\"statements\":[[18,4,[[32,2]]]],\"parameters\":[]},{\"statements\":[[1,[32,2]]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"liquid-versions\",\"liquid-container\"]}",
    "moduleName": "liquid-fire/templates/components/liquid-bind.hbs"
  });
});
define("ember-svg-jar/inlined/members-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>members-placeholder</title><path clip-rule=\"evenodd\" d=\"M9.525 1.275a3.5 3.5 0 114.95 4.95 3.5 3.5 0 01-4.95-4.95zM12 1.25a2.5 2.5 0 100 5 2.5 2.5 0 000-5z\"/><path clip-rule=\"evenodd\" d=\"M8.995 8.995A4.25 4.25 0 0116.25 12v2.25a.5.5 0 01-.5.5h-1.059l-.695 5.562a.5.5 0 01-.496.438h-3a.5.5 0 01-.496-.438l-.695-5.562H8.25a.5.5 0 01-.5-.5V12a4.25 4.25 0 011.245-3.005zM12 8.75A3.25 3.25 0 008.75 12v1.75h1a.5.5 0 01.496.438l.695 5.562h2.118l.695-5.562a.5.5 0 01.496-.438h1V12A3.25 3.25 0 0012 8.75zM2.555 4.055a2.75 2.75 0 113.89 3.89 2.75 2.75 0 01-3.89-3.89zM4.5 4.25a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zm-.628 5.048c.667-.1 1.348-.04 1.987.175a.5.5 0 01-.318.948A3.252 3.252 0 001.25 13.5v.249h1a.5.5 0 01.493.418l.68 4.082h2.153l.307-1.838a.5.5 0 11.986.164l-.376 2.256A.5.5 0 016 19.25H3a.5.5 0 01-.493-.418l-.68-4.082H.75a.5.5 0 01-.5-.5v-.75a4.255 4.255 0 013.622-4.202zm13.683-5.243a2.75 2.75 0 113.89 3.89 2.75 2.75 0 01-3.89-3.89zm1.945.195a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zm.479 6.037a3.252 3.252 0 00-1.52.134.5.5 0 01-.32-.948 4.253 4.253 0 015.61 4.026v.751a.5.5 0 01-.5.5h-1.076l-.68 4.082a.5.5 0 01-.494.418h-3a.5.5 0 01-.493-.418l-.376-2.256a.5.5 0 11.986-.164l.307 1.838h2.153l.68-4.082a.5.5 0 01.493-.418h1v-.25a3.253 3.253 0 00-2.77-3.213z\"/><path clip-rule=\"evenodd\" d=\"M1.249 20.99v.003-.002zm.001.005a.5.5 0 00-1 .005c0 .337.173.61.38.811.204.2.48.37.79.517.623.294 1.493.547 2.527.753 2.077.416 4.924.669 8.053.669 3.13 0 5.976-.253 8.053-.669 1.034-.206 1.904-.459 2.527-.753.31-.147.586-.316.79-.517.207-.201.38-.474.38-.811a.5.5 0 00-1-.006m0 0a.05.05 0 000 .006v-.006a.303.303 0 01-.078.102c-.095.092-.26.205-.52.328-.515.244-1.294.477-2.295.677-1.995.399-4.773.649-7.857.649-3.084 0-5.862-.25-7.857-.65-1.001-.2-1.78-.432-2.295-.676-.26-.123-.425-.236-.52-.328a.304.304 0 01-.078-.102m21.5-.003v.002-.002z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
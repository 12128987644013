define("ember-svg-jar/inlined/analytics-tab-feedback-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Feedback</title><path d=\"M2.99 15.588c.286.147.573.293.86.438 5.306 2.681 5.047 2.159 9.589 2.159 1.896 0 2.86-1.234 3.42-3.042v-.011l1.38-4.601v-.009a1.44 1.44 0 00-1.384-1.858h-3.544a1.458 1.458 0 01-1.146-.56 1.43 1.43 0 01-.252-1.246l.638-3.614a1.222 1.222 0 00-.683-1.432 1.242 1.242 0 00-1.526.411L6.808 8.865a1.459 1.459 0 01-1.182.608H2.99m0-1.458v10.21\" stroke=\"#15171A\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  };
});
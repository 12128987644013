define("ember-svg-jar/inlined/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Comment</title><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M12.658 2a9.307 9.307 0 00-8.15 4.788 9.326 9.326 0 00.23 9.456L2 22l5.75-2.74a9.32 9.32 0 0013.894-5.372 9.341 9.341 0 00-1.532-8.185A9.328 9.328 0 0012.658 2z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.223 3.258a.5.5 0 10-.156-.988A3.833 3.833 0 002.88 5.457a.5.5 0 10.988.156 2.833 2.833 0 012.355-2.355z\" fill=\"#2D264B\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.333.223a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm-5.5 6.5a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0z\"/><path d=\"M13.02 11.703a.5.5 0 10-.707.707l2.666 2.667a.5.5 0 10.708-.707l-2.667-2.667z\" fill=\"#2D264B\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "currentColor"
    }
  };
});
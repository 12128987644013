define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Hyperlink 3 Streamline Icon: https://streamlinehq.com</desc><path d=\"M9.364 18.5l-.932.932a4.5 4.5 0 01-6.364-6.364l4.773-4.774a4.5 4.5 0 016.825 5.825\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/><path d=\"M14.818 5.567l.75-.75a4.5 4.5 0 016.364 6.364l-4.773 4.773a4.5 4.5 0 01-6.824-5.826\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
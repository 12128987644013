define("liquid-fire/templates/components/get-outlet-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    {{yield (-get-dynamic-var "outletState")}}
  */
  {
    "id": "5PUr2TMd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[\"outletState\"],null]]]],\"hasEval\":false,\"upvars\":[\"-get-dynamic-var\"]}",
    "moduleName": "liquid-fire/templates/components/get-outlet-state.hbs"
  });
});
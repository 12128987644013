define("ember-svg-jar/inlined/event-received-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.24 18.555l-2.397-2.387-2.574 1.33.1-3.815-3.014-3.014a1.21 1.21 0 01-.33-1.1 1.232 1.232 0 01.814-.945l11.8-3.937a1.232 1.232 0 011.584 1.583l-2.115 6.34m1.763-7.681L8.368 13.683\" stroke=\"#6C747D\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M15 16.11l1.823 1.823a.25.25 0 00.354 0l4.323-4.324\" stroke=\"#30CF43\" stroke-width=\"1.44\" stroke-linecap=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
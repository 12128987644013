define("ember-svg-jar/inlined/chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.25 12.75V6H16.5\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M23.25 6l-7.939 7.939a1.5 1.5 0 01-2.122 0l-3.128-3.128a1.5 1.5 0 00-2.122 0L.75 18\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5"
    }
  };
});
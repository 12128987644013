define("ember-svg-jar/inlined/selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.573 1.117a.313.313 0 00-.48-.4L4.847 3.412c-.452.541-.77.921-1.044 1.17-.27.242-.455.32-.637.32s-.368-.078-.637-.32c-.275-.249-.592-.629-1.043-1.17l-.58-.695a.313.313 0 00-.48.4l.595.714c.432.518.777.933 1.09 1.215.322.29.652.48 1.055.48.403 0 .733-.19 1.056-.48.312-.282.657-.697 1.089-1.215l2.262-2.714z\" fill=\"#fff\"/>",
    "attrs": {
      "style": "width:8px;height:6px",
      "viewBox": "0 0 8 6",
      "fill": "currentColor"
    }
  };
});
define("ember-element-helper/helpers/element", ["exports", "@embroider/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // eslint-disable-next-line ember/no-classic-components
  function UNINITIALIZED() {}
  class ElementHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      this.tagName = UNINITIALIZED;
      this.componentClass = null;
    }
    compute(params, hash) {
      (true && !(params.length === 1) && Ember.assert('The `element` helper takes a single positional argument', params.length === 1));
      (true && !(Object.keys(hash).length === 0) && Ember.assert('The `element` helper does not take any named arguments', Object.keys(hash).length === 0));
      let tagName = params[0];
      if (tagName !== this.tagName) {
        this.tagName = tagName;
        if (typeof tagName === 'string') {
          this.componentClass = (0, _util.ensureSafeComponent)(class DynamicElement extends Ember.Component {
            constructor(...args) {
              super(...args);
              _defineProperty(this, "tagName", tagName);
            } // eslint-disable-line ember/require-tagless-components
          }, this);
        } else {
          this.componentClass = null;
          Ember.runInDebug(() => {
            let message = 'The argument passed to the `element` helper must be a string';
            try {
              message += ` (you passed \`${tagName}\`)`;
            } catch (e) {
              // ignore
            }
            (true && !(tagName === undefined || tagName === null) && Ember.assert(message, tagName === undefined || tagName === null));
          });
        }
      }
      return this.componentClass;
    }
  }
  _exports.default = ElementHelper;
});
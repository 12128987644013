define("ember-svg-jar/inlined/film-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>film-camera</title><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 19a3 3 0 00-3 3v18a3 3 0 003 3h23a3 3 0 003-3V22a3 3 0 00-3-3H6zm-5 3a5 5 0 015-5h23a5 5 0 015 5v18a5 5 0 01-5 5H6a5 5 0 01-5-5V22zM8.5 3a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM2 7.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM25.5 7a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM21 9.5a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M46.539 20.158A1 1 0 0147 21v20a1 1 0 01-1.42.908l-13-6a1 1 0 01.84-1.816L45 39.437V22.563l-11.58 5.345a1 1 0 01-.84-1.816l13-6a1 1 0 01.959.066z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 48 48"
    }
  };
});
define("ember-svg-jar/inlined/brackets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brackets</title><path d=\"M7.629 4.5l-6.44 6.439a1.5 1.5 0 000 2.121l6.44 6.44m8.742-15l6.44 6.439a1.5 1.5 0 010 2.121l-6.44 6.44\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/warning-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<desc>Road Sign Warning Streamline Icon: https://streamlinehq.com</desc><path d=\"M23.25 23.23a.75.75 0 00.66-1.1l-11.25-21a.78.78 0 00-1.32 0l-11.25 21a.73.73 0 000 .74.73.73 0 00.64.36zM12 20.48A1.5 1.5 0 1113.5 19a1.5 1.5 0 01-1.5 1.48zm0-12.25a1 1 0 011 1v5.47a1 1 0 01-2 0V9.23a1 1 0 011-1z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
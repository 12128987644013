define("ember-svg-jar/inlined/image-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.75 3.188a2.062 2.062 0 100 4.124 2.062 2.062 0 000-4.125zM5.812 5.25a.937.937 0 111.875 0 .937.937 0 01-1.875 0z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.848.28C9.99.187 8.91.187 7.534.187h-.068c-1.377 0-2.457 0-3.314.093-.874.095-1.582.292-2.187.731a4.313 4.313 0 00-.954.954c-.44.605-.636 1.313-.73 2.187C.186 5.01.186 6.09.186 7.466v.068c0 1.377 0 2.457.093 3.314.095.874.292 1.582.731 2.187l.03.04c.26.35.571.658.924.914.605.44 1.313.636 2.187.73.857.094 1.937.094 3.314.094h.068c1.377 0 2.457 0 3.314-.093.874-.095 1.582-.292 2.187-.731.366-.266.688-.588.954-.954.652-.898.779-2.044.812-3.66.011-.549.011-1.17.011-1.87v-.039c0-1.377 0-2.457-.092-3.314-.095-.874-.292-1.582-.731-2.187a4.313 4.313 0 00-.954-.954c-.605-.44-1.313-.636-2.187-.73zM2.626 1.921c.381-.277.87-.438 1.648-.522.788-.086 1.807-.087 3.226-.087s2.438.001 3.226.087c.779.084 1.267.245 1.648.522.27.197.508.435.705.705.277.381.438.87.522 1.648.086.788.086 1.807.086 3.226v.553a3.385 3.385 0 00-.228-.179 4.313 4.313 0 00-5.844.796 4.645 4.645 0 00-.362.532l-.093-.019c-.825-.165-1.33-.266-1.803-.256A4.313 4.313 0 002.1 10.52c-.203.25-.374.542-.58.939a5.72 5.72 0 01-.122-.732c-.086-.79-.087-1.808-.087-3.227 0-1.419.001-2.438.087-3.226.084-.779.245-1.267.522-1.648.197-.27.435-.508.705-.705zm10.181 6.87c.186.132.4.339.864.8-.043 1.478-.192 2.232-.592 2.783-.197.27-.435.508-.705.705-.381.277-.87.438-1.648.522-.788.086-1.807.086-3.226.086-.556 0-1.051 0-1.495-.005l1.74-3.077c.097-.172.181-.322.257-.454.24-.42.37-.627.486-.771a3.187 3.187 0 014.32-.589zM4.76 13.598a.583.583 0 00-.021.043c-.164-.01-.319-.024-.465-.04-.779-.084-1.267-.245-1.648-.522a3.186 3.186 0 01-.458-.406c.42-.843.595-1.184.806-1.444a3.188 3.188 0 012.407-1.177c.288-.006.607.046 1.282.18L4.76 13.598z\" fill=\"#fff\"/>",
    "attrs": {
      "style": "width:18px;height:18px",
      "viewBox": "0 0 15 15",
      "fill": "none"
    }
  };
});
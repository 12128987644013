define("ember-power-calendar/components/power-calendar/nav", ["exports", "@ember-decorators/component", "ember-power-calendar/templates/components/power-calendar/nav"], function (_exports, _component, _nav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  let _class = _exports.default = (_dec = (0, _component.layout)(_nav.default), _dec2 = (0, _component.tagName)(''), _dec(_class2 = _dec2(_class2 = class _class2 extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "unit", 'month');
      _defineProperty(this, "format", 'MMMM YYYY');
    }
  }) || _class2) || _class2);
});
define("ember-svg-jar/inlined/warning-circle-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#warning-circle-filled_svg__clip0_12_38)\"><path d=\"M7 .5a7.14 7.14 0 00-4.976 2.129A6.913 6.913 0 000 7.622 6.872 6.872 0 006.883 14.5H7a7.064 7.064 0 007-7.123A6.867 6.867 0 007 .5zm-.875 9.648A.863.863 0 017 9.25a.892.892 0 01.832 1.196.858.858 0 01-.803.554.893.893 0 01-.904-.852zm.292-2.333v-3.5a.583.583 0 111.166 0v3.5a.583.583 0 11-1.166 0z\" fill=\"currentColor\"/></g><defs><clipPath id=\"warning-circle-filled_svg__clip0_12_38\"><path fill=\"#fff\" transform=\"translate(0 .5)\" d=\"M0 0h14v14H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 14 15",
      "fill": "none"
    }
  };
});
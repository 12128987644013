define("ember-svg-jar/inlined/event-signed-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.246 12.144a4.242 4.242 0 100-8.484 4.242 4.242 0 000 8.484zM4 18.761a8.484 8.484 0 0110.5-3.42\" stroke=\"#6C747D\" stroke-width=\"1.714\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M17.54 16.077V23m-3.463-3.46H21\" stroke=\"#30CF43\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
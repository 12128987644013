define("ember-svg-jar/inlined/unsplash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>unsplash</title><path d=\"M83.86 54.15v34.13H38.57V54.15H0v68.26h122.43V54.15H83.86zM38.57 0h45.3v34.13h-45.3z\"/>",
    "attrs": {
      "viewBox": "0 0 122.43 122.41",
      "fill": "currentColor"
    }
  };
});
define("ember-svg-jar/inlined/check-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-circle</title><path class=\"check-circle_svg__animated-check-circle\" d=\"M19.6 2.715A11.928 11.928 0 0012 0C5.39 0 0 5.39 0 12s5.39 12 12 12 12-5.39 12-12c0-2.283-.637-4.407-1.726-6.212l-11.23 11.256-5.548-5.548\" stroke=\"#000\" stroke-width=\"2\" fill=\"none\"/>",
    "attrs": {
      "viewBox": "-1 -1 26 26"
    }
  };
});
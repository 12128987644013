define("ember-svg-jar/inlined/pages-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pages-placeholder</title><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.5 2.492a2 2 0 00-2 2v33a2 2 0 002 2h33a2 2 0 002-2v-33a2 2 0 00-2-2h-33zm-4 2a4 4 0 014-4h33a4 4 0 014 4v33a4 4 0 01-4 4h-33a4 4 0 01-4-4v-33zm-5 2a1 1 0 011 1v36a2 2 0 002 2h36a1 1 0 110 2h-36a4 4 0 01-4-4v-36a1 1 0 011-1zM14 11a2 2 0 012-2h22a2 2 0 012 2v6a2 2 0 01-2 2H16a2 2 0 01-2-2v-6zm24 0H16v6h22v-6zM15.5 24a1 1 0 011-1h21a1 1 0 110 2h-21a1 1 0 01-1-1zm0 6.492a1 1 0 011-1h15a1 1 0 110 2h-15a1 1 0 01-1-1z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 48 48"
    }
  };
});
define("ember-svg-jar/inlined/analytics-tab-sent-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Sent</title><path d=\"M8.385 14.642l2.414 2.404a1.22 1.22 0 001.164.332 1.24 1.24 0 00.897-.82l3.965-11.885a1.24 1.24 0 00-1.595-1.595L3.345 7.043a1.24 1.24 0 00-.82.953 1.218 1.218 0 00.332 1.107l3.035 3.036-.1 3.843 2.593-1.34zM16.467 3.326L5.889 12.143\" stroke=\"#15171A\" stroke-width=\"1.3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  };
});
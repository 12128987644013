define("ember-svg-jar/inlined/members-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>members-outline</title><defs><style>.members-outline_svg__cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px}</style></defs><g class=\"members-outline_svg__g3\"><circle class=\"members-outline_svg__cls-1\" cx=\"4.5\" cy=\"6\" r=\"2.25\"/><path class=\"members-outline_svg__cls-1\" d=\"M4.5 9.75A3.75 3.75 0 00.75 13.5v2.25h1.5l.75 6h3\"/></g><g class=\"members-outline_svg__g2\"><circle class=\"members-outline_svg__cls-1\" cx=\"19.5\" cy=\"6\" r=\"2.25\"/><path class=\"members-outline_svg__cls-1\" d=\"M19.5 9.75a3.75 3.75 0 013.75 3.75v2.25h-1.5l-.75 6h-3\"/></g><g class=\"members-outline_svg__g1\"><circle class=\"members-outline_svg__cls-1\" cx=\"12\" cy=\"3.75\" r=\"3\"/><path class=\"members-outline_svg__cls-1\" d=\"M17.25 13.5a5.25 5.25 0 00-10.5 0v2.25H9l.75 7.5h4.5l.75-7.5h2.25z\"/></g>",
    "attrs": {
      "id": "members-outline_svg__Regular",
      "viewBox": "0 0 24 24"
    }
  };
});
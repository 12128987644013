define("ember-svg-jar/inlined/external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>external</title><path clip-rule=\"evenodd\" d=\"M22.707 1.293a1 1 0 010 1.414l-11 11a1 1 0 01-1.414-1.414l11-11a1 1 0 011.414 0z\"/><path clip-rule=\"evenodd\" d=\"M13 2a1 1 0 011-1h8a1 1 0 011 1v8a1 1 0 11-2 0V3h-7a1 1 0 01-1-1zM1 2a1 1 0 011-1h7a1 1 0 010 2H3v18h18v-6a1 1 0 112 0v7a1 1 0 01-1 1H2a1 1 0 01-1-1V2z\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/analytics-tab-opened", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 3C5.365 2.954 2.641 4.944.93 6.98a1.23 1.23 0 00-.28.787c0 .29.1.57.28.786 1.675 1.994 4.39 4.03 7.07 3.981 2.678.049 5.395-1.987 7.071-3.981.18-.216.28-.496.28-.786 0-.29-.1-.57-.28-.787C13.357 4.943 10.633 2.953 8 3z\" stroke=\"#7E8B99\" stroke-width=\"1.25\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M9.82 7.764v0c0 .411-.113.809-.32 1.143a1.877 1.877 0 01-.823.734 1.655 1.655 0 01-1.029.11 1.774 1.774 0 01-.927-.539c-.26-.28-.44-.643-.514-1.045a2.224 2.224 0 01.108-1.193A1.99 1.99 0 017 6.068c.3-.216.645-.328.995-.328h0c.233 0 .464.05.682.147.219.098.42.242.592.428s.31.41.406.66c.096.248.145.517.145.79z\" stroke=\"#7E8B99\" stroke-width=\"1.25\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none"
    }
  };
});
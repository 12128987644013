define("ember-svg-jar/inlined/right-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.37 5.723a.625.625 0 00-.88.888l1.175 1.164c.48.475.8.795 1.016 1.064l.029.036H5.93a.625.625 0 100 1.25h5.78l-.029.036c-.216.27-.537.589-1.016 1.064L9.49 12.389a.625.625 0 10.88.888l1.2-1.19c.447-.443.82-.812 1.086-1.143.278-.347.49-.712.547-1.156a2.274 2.274 0 000-.576c-.057-.444-.268-.809-.547-1.156-.266-.33-.639-.7-1.085-1.143l-1.201-1.19z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.263.542a8.958 8.958 0 100 17.916 8.958 8.958 0 000-17.916zM1.555 9.5a7.708 7.708 0 1115.416 0 7.708 7.708 0 01-15.416 0z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 19 19",
      "fill": "currentColor"
    }
  };
});
define("ember-svg-jar/inlined/arrow-up-stroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-up-stroke</title><path d=\"M.75 17.189L11.47 6.47a.72.72 0 01.53-.22.72.72 0 01.53.22l10.72 10.719\" stroke=\"#000\" stroke-width=\"1.5\" fill=\"none\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pen</title><defs><style>.pen_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"pen_svg__a\" d=\"M21.982 2.018a3.565 3.565 0 00-5.064.034L2.713 16.256.98 23.02l6.764-1.734L21.948 7.08a3.562 3.562 0 00.034-5.063zM16.512 2.459l5.03 5.029\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-upload</title><defs><style>.file-upload_svg__a{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}</style></defs><path class=\"file-upload_svg__a\" d=\"M8.25 14.25L12 18l3.75-3.75M12 6.75V18\"/><circle class=\"file-upload_svg__a\" cx=\"12\" cy=\"12\" r=\"11.25\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("liquid-fire/mixins/growable", ["exports", "liquid-fire/promise", "velocity"], function (_exports, _promise, _velocity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    growDuration: 250,
    growPixelsPerSecond: 200,
    growEasing: 'slide',
    shrinkDelay: 0,
    growDelay: 0,
    growWidth: true,
    growHeight: true,
    transitionMap: Ember.inject.service('liquid-fire-transitions'),
    animateGrowth(elt, have, want) {
      this.transitionMap.incrementRunningTransitions();
      let adaptations = [];
      if (this.growWidth) {
        adaptations.push(this._adaptDimension(elt, 'width', have, want));
      }
      if (this.growHeight) {
        adaptations.push(this._adaptDimension(elt, 'height', have, want));
      }
      return _promise.default.all(adaptations).then(() => {
        this.transitionMap.decrementRunningTransitions();
      });
    },
    _adaptDimension(elt, dimension, have, want) {
      if (have[dimension] === want[dimension]) {
        return _promise.default.resolve();
      }
      let target = {};
      target['outer' + Ember.String.capitalize(dimension)] = [want[dimension], have[dimension]];
      return (0, _velocity.default)(elt[0], target, {
        delay: this._delayFor(have[dimension], want[dimension]),
        duration: this._durationFor(have[dimension], want[dimension]),
        queue: false,
        easing: this.growEasing || this.constructor.prototype.growEasing
      });
    },
    _delayFor(before, after) {
      if (before > after) {
        return this.shrinkDelay || this.constructor.prototype.shrinkDelay;
      }
      return this.growDelay || this.constructor.prototype.growDelay;
    },
    _durationFor(before, after) {
      return Math.min(this.growDuration || this.constructor.prototype.growDuration, 1000 * Math.abs(before - after) / (this.growPixelsPerSecond || this.constructor.prototype.growPixelsPerSecond));
    }
  });
});
define("ember-svg-jar/inlined/portal-icon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>portal-icon-1</title><path d=\"M10.533 11.267a5.135 5.135 0 10-.001-10.27 5.135 5.135 0 00.001 10.27zM1 23a9.531 9.531 0 0116.274-6.741 9.532 9.532 0 012.793 6.74\" stroke=\"#fff\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "viewBox": "0 0 21 24",
      "fill": "none"
    }
  };
});
define("ember-svg-jar/inlined/thumbs-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M2.001 10.571h3.443a1.907 1.907 0 001.543-.794l4.618-7.095a1.62 1.62 0 011.992-.537 1.598 1.598 0 01.892 1.871l-.832 3.14a1.867 1.867 0 00.993 2.165c.259.127.544.193.832.194h4.63a1.883 1.883 0 011.807 2.426v.011l-1.8 6.008v.015c-.733 2.36-1.993 3.97-4.47 3.97-5.933 0-5.593.684-12.524-2.818-.375-.188-.75-.38-1.125-.57v-9.89\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/arrow-top-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.31 1.522c.587-.002.991-.004 1.298.03.1.011.184.025.255.042l-6.2 6.2a.437.437 0 10.619.619l6.2-6.2c.017.07.03.154.042.254.033.308.032.712.03 1.3l-.007 1.447a.437.437 0 10.875.004l.007-1.474c.002-.555.004-1.009-.036-1.372-.04-.377-.132-.711-.354-.999a1.88 1.88 0 00-.337-.336c-.287-.223-.621-.314-.998-.355-.363-.04-.817-.037-1.372-.035L8.858.654a.437.437 0 10.004.875l1.448-.007z\" fill=\"#113139\"/><path d=\"M7.415 2.508c-.353-.004-.742-.004-1.17-.004h-.028c-1.072 0-1.911 0-2.578.072-.68.074-1.23.227-1.7.568a3.354 3.354 0 00-.743.742c-.341.47-.494 1.022-.568 1.702-.072.666-.072 1.506-.072 2.577v.053c0 1.071 0 1.91.072 2.577.074.68.227 1.23.568 1.701.207.285.458.535.742.742.47.342 1.022.495 1.701.569.667.072 1.506.072 2.578.072h.053c1.07 0 1.91 0 2.577-.072.68-.074 1.23-.227 1.701-.569.285-.207.535-.457.742-.742.342-.47.495-1.021.569-1.701.072-.666.072-1.506.072-2.577v-.029c0-.427 0-.816-.005-1.17a.437.437 0 10-.874.01c.004.348.004.733.004 1.162 0 1.104 0 1.896-.067 2.51-.066.605-.192.984-.407 1.28-.153.211-.338.396-.548.55-.297.215-.676.34-1.281.406-.614.066-1.406.067-2.51.067-1.103 0-1.896 0-2.51-.067-.605-.066-.984-.191-1.28-.407a2.479 2.479 0 01-.549-.548c-.215-.296-.34-.676-.406-1.281-.067-.614-.067-1.406-.067-2.51 0-1.103 0-1.896.067-2.51.065-.605.19-.984.406-1.28a2.48 2.48 0 01.549-.549c.296-.215.675-.34 1.28-.406.614-.067 1.407-.067 2.51-.067.43 0 .814 0 1.162.004a.438.438 0 00.01-.875z\"/>",
    "attrs": {
      "viewBox": "0 0 14 14",
      "fill": "currentColor"
    }
  };
});
define("ember-svg-jar/inlined/book-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-open</title><path d=\"M11.962 22.112a2.307 2.307 0 01-.758-.137 12.916 12.916 0 00-4.771-.906c-1.181 0-2.35.16-3.474.475a2.351 2.351 0 01-2.271-.614A2.343 2.343 0 010 19.262V4.929a2.35 2.35 0 011.644-2.243 14.526 14.526 0 014.746-.792c1.837 0 3.634.339 5.342 1.008.047.017.11.029.186.035a.727.727 0 01.164.001.822.822 0 00.208-.043 14.51 14.51 0 015.316-1.001c1.628 0 3.232.268 4.768.798A2.339 2.339 0 0124 4.927v14.336a2.345 2.345 0 01-1.156 2.031 2.353 2.353 0 01-1.787.255 12.935 12.935 0 00-3.491-.478c-1.641 0-3.239.302-4.747.897-.256.093-.514.14-.777.145l-.041.002-.039-.003zM6.43 19.569c1.659 0 3.277.276 4.82.822V4.322l-.045-.016a13.145 13.145 0 00-9.09-.196.862.862 0 00-.615.82v14.332a.855.855 0 001.07.832 14.363 14.363 0 013.86-.525zm6.32.822a14.344 14.344 0 014.816-.822c1.319 0 2.624.178 3.879.53a.858.858 0 001.054-.835V4.929a.853.853 0 00-.597-.813 13.155 13.155 0 00-9.085.183l-.067.023v16.069z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/filter-dropdown-payments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.15 3.3V.8m-2.5 9.166c0 1.25 1.117 1.667 2.5 1.667s2.5 0 2.5-1.667c0-2.5-5-2.5-5-5C2.65 3.3 3.767 3.3 5.15 3.3s2.5.633 2.5 1.666m-2.5 6.667v2.5m6.508-3.333v4.615M9.35 13.108h4.616\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
});
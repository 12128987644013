define("ember-svg-jar/inlined/email-love-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-love-letter</title><path d=\"M23.991 11.464l-.036-.146-.028-.068-.011-.027-.115-.114-.018-.021-.008-.005h-.001L20 8.487V.5a.5.5 0 00-.5-.5h-15a.5.5 0 00-.5.5v7.987L.226 11.082l-.003.002-.006.004-.015.016-.118.118-.011.027-.028.068-.036.146L0 11.5V22c0 1.103.897 2 2 2h20c1.103 0 2-.897 2-2V11.5l-.009-.036zm-1.383.03L20 13.232V9.701l2.608 1.793zM4 13.232l-2.608-1.739L4 9.701v3.531zM22 23H2c-.551 0-1-.449-1-1v-9.566l5.223 3.482a.5.5 0 00.554-.832L5 13.899V1h14v12.899l-1.777 1.185a.5.5 0 10.554.832L23 12.434V22c0 .551-.449 1-1 1zm-6.188-6.891A.502.502 0 0015.5 16h-7a.502.502 0 00-.312.109l-5 4a.501.501 0 00.625.781L8.675 17h6.649l4.863 3.891a.505.505 0 00.704-.079.501.501 0 00-.078-.703l-5.001-4zm-4.106-3.33a.497.497 0 00.588 0C12.776 12.429 17 9.282 17 6.678c0-1.868-1.387-2.984-2.728-2.984-.772 0-1.674.379-2.272 1.368-.598-.988-1.5-1.368-2.272-1.368C8.387 3.693 7 4.81 7 6.678c0 2.604 4.224 5.751 4.706 6.101zM9.728 4.693c.844 0 1.511.681 1.786 1.822.108.45.864.45.973 0 .274-1.141.942-1.822 1.786-1.822.85 0 1.728.742 1.728 1.984 0 1.646-2.658 4.037-4 5.072-1.342-1.035-4-3.426-4-5.072C8 5.436 8.878 4.693 9.728 4.693z\"/>",
    "attrs": {
      "version": "1",
      "viewBox": "0 0 24 24"
    }
  };
});
define("ember-svg-jar/inlined/arrow-right-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#arrow-right-pagination_svg__clip0_173_12425)\"><path d=\"M1.424 1.455a1.501 1.501 0 00.03 2.122l8.15 7.928-8.15 7.929a1.5 1.5 0 002.091 2.152l8.82-8.58a2.102 2.102 0 00.47-2.316 2.086 2.086 0 00-.47-.685l-8.82-8.58a1.5 1.5 0 00-2.12.03z\"/></g><defs><clipPath id=\"arrow-right-pagination_svg__clip0_173_12425\"><path fill=\"#fff\" d=\"M0 0h14v23H0z\"/></clipPath></defs>",
    "attrs": {
      "viewBox": "0 0 14 23"
    }
  };
});
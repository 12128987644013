define("liquid-wormhole/components/liquid-append", ["exports", "@ember-decorators/component", "liquid-wormhole/templates/components/liquid-append", "jquery"], function (_exports, _component, _liquidAppend, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let LiquidAppend = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_liquidAppend.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class LiquidAppend extends Ember.Component {
    _didUpdate(element) {
      if (this.replaceNodes) {
        const nodes = this.nodes;
        (0, _jquery.default)(element).children().remove();
        (0, _jquery.default)(element).append(nodes);
      }
    }
    _didInsert(element) {
      const notify = this.notify;
      const nodes = this.nodes;
      if (notify && notify.willAppendNodes) {
        notify.willAppendNodes(element);
      }
      (0, _jquery.default)(element).append(nodes);
      if (notify && notify.didAppendNodes) {
        notify.didAppendNodes(element);
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "_didUpdate", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_didUpdate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_didInsert", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "_didInsert"), _class2.prototype)), _class2)) || _class) || _class);
});
define("ember-infinity/templates/components/infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "m0ErSvCY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"isVisible\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[31,[[32,0,[\"loaderClassNames\"]],[30,[36,0],[[32,0,[\"viewportEntered\"]],\" in-viewport\"],null],[30,[36,0],[[32,0,[\"isDoneLoading\"]],\" reached-infinity\"],null]]]],[24,\"data-test-infinity-loader\",\"\"],[4,[38,1],[[32,0,[\"didInsertLoader\"]],[32,0]],null],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[32,0,[\"infinityModelContent\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isDoneLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[32,0,[\"loadedText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[32,0,[\"loadingText\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\"]}",
    "moduleName": "ember-infinity/templates/components/infinity-loader.hbs"
  });
});